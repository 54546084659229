import { Box, Container, Stack, Tab, Tabs, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import SwitchSimple from "components/presentationals/Switch/SwitchSimple";
import { Table } from "components/presentationals/Table";
import { useAppSelector } from "hooks/useRedux";
import IPage from "interfaces/page";
import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import {
  getEvaluated,
  getEvaluations,
  getOdis,
  getPeriods,
  putEvaluations,
  putOdis,
} from "services/eval_odis";
import { TPageState } from "types";
import { getFormatDateString } from "utils/helpers";

const Evaluations: React.FC<IPage> = () => {
  const [pageState, setPageState] = React.useState<TPageState>({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 8,
  });
  const [periods, setPeriods] = React.useState([]) as any;

  const [evaluated, setEvaluated] = React.useState({}) as any;

  const [handleActive, setHandleActive] = React.useState(false);

  const [tabs, setTabs] = React.useState(0 as number);

  const { user } = useAppSelector((state) => state.authReducer);

  const { cedula, nacionalidad } = user;

  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setTabs(newValue);
  };

  /* Periodo */
  const getPeriodsAll = async () => {
    try {
      const response: any = await getPeriods({ authRequire: true });
      setPeriods(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  /* Informacion del evaluado */

  const getInfoEvaluated = async () => {
    try {
      const response = await getEvaluated({
        authRequire: true,
        params: {
          cedula: cedula,
          limit: 10,
          page: 1,
        },
      });

      setEvaluated(response.data.data.rows[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const getEvaluationsAll = async (data) => {
    try {
      const response: any = await getEvaluations({
        params: { ...data, page: data.page === 0 ? 1 : data.page },
        authRequire: true,
      });

      setPageState((prev) => ({
        ...prev,
        isLoading: false,
        data: response.data?.data?.rows,
        total: response.data?.data?.count,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const getOdisAll = async (data) => {
    try {
      const response: any = await getOdis({
        params: { ...data, page: data.page === 0 ? 1 : data.page },
        authRequire: true,
      });

      setPageState((prev) => ({
        ...prev,
        isLoading: false,
        data: response.data.data.rows,
        total: response.data.data.rows.length,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatus = async (params, setActivate, e, type, evaluated_id) => {
    try {
      setPageState((prev) => ({ ...prev, isLoading: true }));

      if (type === "evaluaciones") {
        await putEvaluations({
          params: { id: params.row.id },
          payload: {
            estado_id: e.target.checked ? 2 : 1,
          },
        });
        setHandleActive((prev) => !prev);

        return;
      } else {
        await putOdis({
          params: { id: params.row.id },
          payload: {
            estado: !params.row.estado,
          },
        });
        setHandleActive((prev) => !prev);
      }
      setActivate((prev) => !prev);
    } catch (error) {
      console.log(error);
    } finally {
      setPageState((prev) => ({ ...prev, isLoading: false }));
    }
  };

  const columnsOdis: GridColDef[] = useMemo(
    () => [
      {
        field: "descripcion",
        headerName: "Descripcion",
        minWidth: 500,
        flex: 1,
      },
      {
        field: "evaluador",
        headerName: "Evaluador",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "valor",
        headerName: "Valor",
        minWidth: 60,
        flex: 1,
      },
      {
        field: "actions",
        headerName: "aprobación",
        renderCell: (params) => (
          <SwitchSimple
            name={params.row.id}
            checked={params.row.estado}
            handleChecked={(e, setActivate) => {
              handleStatus(params, setActivate, e, "odis", evaluated);
            }}
            disabled={params.row.estado}
          />
        ),
        sortable: false,
        width: 100,
        headerAlign: "center",
        filterable: false,
        align: "center",
        disableColumnMenu: true,
        disableReorder: true,
      },
    ],
    []
  );

  //Colums para evaluaciones

  const columnsEvaluaciones: GridColDef[] = useMemo(
    () => [
      {
        field: "calificacion_competencias",
        headerName: "Calificacion de competencias",
        minWidth: 70,
        flex: 1,
      },
      {
        field: "calificacion_odis",
        headerName: "Calificacion de las odis",
        minWidth: 60,
        flex: 1,
      },
      {
        field: "calificacion_final",
        headerName: "Calificacion final",
        minWidth: 60,
        flex: 1,
      },
      {
        field: "observacion",
        headerName: "Observación",
        minWidth: 400,
        flex: 1,
      },
      {
        field: "actions",
        headerName: "aprobación",
        renderCell: (params) => (
          <SwitchSimple
            name={params.row.id}
            checked={params.row.estado_id === 1 ? false : true}
            handleChecked={(e, setActivate) => {
              handleStatus(params, setActivate, e, "evaluaciones", evaluated);
            }}
            disabled={params.row.estado}
          />
        ),
        sortable: false,
        width: 100,
        headerAlign: "center",
        filterable: false,
        align: "center",
        disableColumnMenu: true,
        disableReorder: true,
      },
    ],
    []
  );

  useEffect(() => {
    if (cedula) {
      getPeriodsAll();
      getInfoEvaluated();
    }
  }, [cedula]);

  useEffect(() => {
    console.log("evaluated", evaluated);
    if (tabs === 0 && periods?.rows?.[0]?.id && evaluated) {
      getOdisAll({
        evaluado_id: evaluated?.id,
        limit: 8,
        page: 1,
      });
    } else if (periods?.rows?.[0]?.id && evaluated) {
      getEvaluationsAll({
        usuario_id: evaluated?.id,
        limit: 8,
        page: 1,
      });
    } else {
      setPageState((prev) => ({
        ...prev,
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 8,
      }));
    }
  }, [tabs, evaluated]);

  useEffect(() => {
    if (tabs === 0 && periods?.rows?.[0]?.id && evaluated) {
      getOdisAll({
        evaluado_id: evaluated?.id,
        limit: pageState.pageSize,
        page: pageState.page,
      });
    } else if (periods?.rows?.[0]?.id && evaluated) {
      getEvaluationsAll({
        usuario_id: evaluated?.id,
        limit: pageState.pageSize,
        page: pageState.page,
      });
    } else {
      setPageState((prev) => ({
        ...prev,
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 8,
      }));
    }
  }, [pageState.page, pageState.pageSize]);

  useEffect(() => {
    (async () => {
      tabs === 0
        ? await getOdisAll({ evaluado_id: evaluated?.id, limit: 8, page: 1 })
        : await getEvaluationsAll({
            usuario_id: evaluated?.id,
            limit: 8,
            page: 1,
          });
    })();
  }, [handleActive]);

  return (
    <>
      <Helmet>
        <title>ME - EVALUACIONES</title>
      </Helmet>

      <section>
        <Container maxWidth="lg">
          {/* <Typography className="page-title" component="h1">
            EVALUACIONES
          </Typography> */}

          {/* <CardNormal> */}
          <Stack spacing={4}>
            <Typography className="page-title" component="h1">
              {periods?.rows?.[0]?.nombre}
            </Typography>
            <div
              style={{
                display: "flex",
                gap: "15px",
                justifyContent: "center",
              }}
            >
              <Typography
                component={"p"}
                style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}
              >
                <span style={{ fontWeight: "bold" }}>Tipo de periodo: </span>
                {periods?.rows?.[0]?.tipo_periodo
                  ? periods?.rows?.[0]?.tipo_periodo === "S"
                    ? " Sede"
                    : " Plantel"
                  : ""}
              </Typography>
              <Typography
                component={"p"}
                style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}
              >
                <span style={{ fontWeight: "bold" }}>Desde: </span>

                {periods?.rows?.[0]?.fecha_desde &&
                  getFormatDateString(
                    periods?.rows?.[0]?.fecha_desde,
                    "dd-MM-yyyy"
                  )}
              </Typography>
              <Typography
                component={"p"}
                style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}
              >
                <span style={{ fontWeight: "bold" }}>Hasta: </span>{" "}
                {periods?.rows?.[0]?.fecha_hasta &&
                  getFormatDateString(
                    periods?.rows?.[0]?.fecha_hasta,
                    "dd-MM-yyyy"
                  )}
              </Typography>
            </div>
          </Stack>
          <Tabs
            value={tabs}
            onChange={handleChangeTabs}
            sx={{ justifyContent: "flex-start", marginTop: "32px" }}
          >
            <Tab label="Odis" sx={tabs === 0 ? { fontWeight: "bold" } : {}} />
            <Tab
              label="Evaluaciones"
              sx={tabs === 1 ? { fontWeight: "bold" } : {}}
            />
          </Tabs>
          <TabPanel value={tabs} index={0}>
            <Table
              pageState={pageState}
              setPageState={setPageState}
              columns={columnsOdis}
              rowsPerPageOptions={[8, 15]}
            />
          </TabPanel>
          <TabPanel value={tabs} index={1}>
            <Table
              pageState={pageState}
              setPageState={setPageState}
              columns={columnsEvaluaciones}
              rowsPerPageOptions={[8, 15]}
            />
          </TabPanel>
          {/* </CardNormal> */}
        </Container>
      </section>
    </>
  );
};

export default Evaluations;
