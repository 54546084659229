import { request } from 'config/request';
import { API_NOMINA } from 'constants/index';
import { iRequest } from 'interfaces/api';
import { Tparam } from 'types';

// get all modules
export const getAllModulesServices = ({ params,isBearer  }: iRequest) => {
  const { page, size } = params as Tparam;
  return request(`${API_NOMINA}/module/table`, {
    method: 'get',
    isBearer,
    params: { page: page + 1, size }
  });
};
// create modules
export const createModulesServices = ({ payload,  }: iRequest) => {
  return request(
  `${API_NOMINA}/module/create`, {
    method: 'post',
    isBearer: true,
    data: payload
  });
};
// update modules
export const updateModulesServices = ({
  payload,params,isBearer
}: iRequest) => {

  return request( `${API_NOMINA}/module/updatemodule/${params}/`
    , {
    method: 'put',
    isBearer,
    data: payload
  });
};
// delete modules
export function deleteModulesServices({ params, isBearer }: iRequest) {
  return request(`${API_NOMINA}/module/deletemodule/${params}/`, {
    method: 'delete',
    isBearer
  });
}
