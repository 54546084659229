// Vendors
import React from "react";
// import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react'
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import ReactDOM from "react-dom/client";
//Redux store
import { store } from "store";

import reportWebVitals from "./reportWebVitals";

import App from "./pages/app/App";
import { HelmetProvider } from "react-helmet-async";
// constants
import { SNACKBARS_IN_DISPLAY } from "constants/index";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// const instance = createInstance({
//   urlBase: "http://matomo.unem.edu.ve",
//   siteId: 16,
//   trackerUrl: "http://matomo.unem.edu.ve/tracking.php", // optional, default value: `${urlBase}matomo.php`
//   srcUrl: "http://matomo.unem.edu.ve/tracking.js", // optional, default value: `${urlBase}matomo.js`
//   disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
//   heartBeat: {
//     // optional, enabled by default
//     active: true, // optional, default value: true
//     seconds: 10, // optional, default value: `15
//   },
//   linkTracking: false, // optional, default value: true
//   configurations: {
//     // optional, default value: {}
//     // any valid matomo configuration, all below are optional
//     disableCookies: true,
//     setSecureCookie: true,
//     setRequestMethod: "POST",
//   },
// });

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <HelmetProvider>
      <SnackbarProvider maxSnack={SNACKBARS_IN_DISPLAY}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SnackbarProvider>
    </HelmetProvider>
    {/* <MatomoTracker siteId={16} matomoUrl="https://matomo.unem.edu.ve" /> */}
  </Provider>
  //  </React.StrictMode>
);

reportWebVitals();
