// Vendors
import axios, { AxiosInstance } from 'axios';
import { localToken } from '../constants';
import { removeItemLocal } from 'utils/helpers';

const API: AxiosInstance = axios.create({
  baseURL: ''
});

API.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (403 === error.response.status || 401 === error.response.status) {
      removeItemLocal(localToken);
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export default API;
