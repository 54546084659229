// Vendors
import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";

// Types
import { IAction, AppState } from "interfaces/index";

const PREFIX = "APP";

const initialState: AppState = {
  modal: { isOpen: false, content: null },
  serverErrors: false,
  alertMessages: {},
  openMenu: false,
  qr: null,
};

const appAdapter = createEntityAdapter<AppState>({});

/**
 * Alerta de respuesta del servidor
 * @descp recibe un objecto que tiene el tipo  y mensaje de la alerta
 * @param message :object, 
 * ,
  @return mixed
 */
export const alertsMessages = createAsyncThunk(
  `${PREFIX}/AlertaMessages`,
  (payload: any) => {
    return payload;
  }
);

export const appSlice = createSlice({
  name: PREFIX,
  initialState: appAdapter.getInitialState(initialState),
  reducers: {
    openModal(state, action: IAction) {
      state.modal.isOpen = !state.modal.isOpen;
      state.modal.content = action.payload;
    },
    closeModal(state) {
      state.modal.isOpen = initialState.modal.isOpen;
      state.modal.content = initialState.modal.content;
    },
    clearErrorsServer(state) {
      state.serverErrors = false;
      state.alertMessages = {};
    },
    toggleDrawer(state) {
      state.openMenu = !state.openMenu;
    },
    setQr(state, action: IAction) {
      state.qr = action.payload;
    },
  },
  extraReducers: (build) => {
    build.addCase(alertsMessages.fulfilled, (state, action) => {
      state.alertMessages = action.payload;
    });
    build.addCase(alertsMessages.rejected, (state, action) => {
      state.alertMessages = action.error;
    });
  },
});

// Actions
export const { clearErrorsServer, toggleDrawer, openModal, closeModal, setQr } =
  appSlice.actions;

// Reducer
export default appSlice.reducer;
