import React from "react";
import DatosTrabajador from "./components/DatosTrabajador";
import ListaCargos from "./components/ListaCargos";

/*MUI  */
import { Typography } from "@mui/material";

/* Hooks */
import { useAppSelector } from "hooks/useRedux";
import { Helmet } from "react-helmet-async";

/* interfaces */
import IPage from "interfaces/page";

/* swiper */
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination } from "swiper/modules";

const Dash: React.FC<IPage> = () => {
  const { isLoading, userData } = useAppSelector((state) => state.userReducer);

  const { user } = useAppSelector((state) => state.authReducer);

  const { email, cedula, nacionalidad, fechana } = user;

  return (
    <>
      <Helmet>
        <title>ME - Inicio</title>
      </Helmet>
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minWidth: "100%",
        }}
      >
        <Typography className="page-title" component="h1">
          DATOS DEL FUNCIONARIO
        </Typography>
        {user?.email === null ? (
          <Typography className="page-title" component="h2" fontSize={16}>
            No tiene datos de Nómina comuníquese con RRHH
          </Typography>
        ) : (
          <>
            <DatosTrabajador
              datos={{
                email,
                cedula,
                nacionalidad,
                fechana,
                nombres: `${userData[0]?.nombre}`,
              }}
              loading={isLoading}
            />

            <Typography className="page-title" component="h2">
              CARGOS DEL TRABAJADOR
            </Typography>

            <Swiper
              navigation={true}
              modules={[Pagination, Navigation]}
              // className={classes.main}
              style={{ width: "100%" }}
              grabCursor={false}
              hashNavigation={false}
              simulateTouch={false}
            >
              {userData.map((datos, idx) => {
                return (
                  <SwiperSlide key={idx}>
                    <ListaCargos
                      datos={datos}
                      loading={isLoading}
                      multipleReceipt={userData.length > 1}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </>
        )}
      </section>
    </>
  );
};

export default Dash;
