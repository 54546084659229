/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Button } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { CustomModal } from "components/presentationals/CustomModal";
import { DialogConfirm } from "components/presentationals/DialogConfirm";
import { ActionsButtonsTable, Table } from "components/presentationals/Table";
import { useEffect, useMemo, useState } from "react";
import { getAllUsersService } from "services/user";
import { TGenericResponse, TPageState } from "types";
import { useAppDispatch } from "../../hooks/useRedux";
import { alertsMessages } from "reducers/appSlice";

import { myStylesTablesPages } from "static/styles-mui";
import {
  createPermissionsServices,
  deletePermissionsServices,
  getAllPermissionsServices,
  updatePermissionsServices,
} from "services/permissions";
import FormPermissions from "./components/FormPermissions";

const initialDataRow = {
  id: "",
  name: "",
  description: "",
};

const Permissions = () => {
  const classes = myStylesTablesPages();
  const dispatch = useAppDispatch();

  const [loadingService, setLoadingService] = useState(false);
  const [rowData, setRowData] = useState({
    data: initialDataRow,
    isNewRecord: true,
  });
  const [pageState, setPageState] = useState<TPageState>({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
  });
  const [openModal, setOpenModal] = useState({
    dialogConfirm: false,
    modalCreate: false,
  });

  const getPermissions = async () => {
    try {
      setPageState((prev: TPageState) => ({ ...prev, isLoading: true }));
      const { data } = await getAllPermissionsServices({
        params: {
          page: pageState.page,
          size: pageState.pageSize,
        },
        isBearer: true,
      });

      setPageState((prev: TPageState) => ({
        ...prev,
        isLoading: false,
        data: data.data,
        total: data?.pageInfo?.totalRecords,
      }));
    } catch (err: any) {
      dispatch(alertsMessages(err?.response?.data?.alert));
      throw Error();
    }
  };

  const handleCreate = () => {
    setOpenModal({ dialogConfirm: false, modalCreate: true });
    setRowData({ data: initialDataRow, isNewRecord: true });
  };

  const handleEditRow = (dataRow: GridCellParams) => () => {
    setOpenModal({ dialogConfirm: false, modalCreate: true });
    setRowData({ data: dataRow.row, isNewRecord: false });
  };

  const handleDeleteRow = (dataRow: GridCellParams) => () => {
    setOpenModal({ dialogConfirm: true, modalCreate: false });
    setRowData({ data: dataRow.row, isNewRecord: false });
  };

  const columns: GridColDef[] = useMemo(
    () => [
      { field: "name", headerName: "Nombre del Permiso", width: 300 },

      { field: "description", headerName: "Descripción", width: 150, flex: 1 },

      {
        field: "actions",
        headerName: "Acciones",
        renderCell: (params) => (
          <ActionsButtonsTable
            handleEditRow={handleEditRow(params)}
            handleDeleteRow={handleDeleteRow(params)}
          />
        ),
        sortable: false,
        width: 100,
        headerAlign: "center",
        filterable: false,
        align: "center",
        disableColumnMenu: true,
        disableReorder: true,
      },
    ],
    []
  );

  const onDeleteRow = async (id: unknown) => {
    try {
      setLoadingService(true);
      (await deletePermissionsServices({
        params: id as any,
        isBearer: true,
      })) as any;
      dispatch(
        alertsMessages({
          name: "success",
          message: "Registro Eliminado Existosamente",
        })
      );
    } catch (error: any) {
      dispatch(alertsMessages(error?.response?.data?.alert));
    } finally {
      setLoadingService(false);
      getPermissions();
      setOpenModal((prevState) => ({ ...prevState, dialogConfirm: false }));
    }
  };

  const onSubmit = async (data: any) => {
    try {
      setLoadingService(true);
      const id = data.id;
      delete data.id;
      if (rowData.isNewRecord) {
        (await createPermissionsServices({
          payload: data,
          isBearer: true,
        })) as TGenericResponse;
        dispatch(
          alertsMessages({
            name: "success",
            message: "¡Se ha creado un permiso exitosamente",
          })
        );
      } else {
        (await updatePermissionsServices({
          payload: data,
          params: id,
          isBearer: true,
        })) as TGenericResponse;
        dispatch(
          alertsMessages({
            name: "success",
            message: "¡Se ha actualizado un permiso exitosamente",
          })
        );
      }
    } catch (error: any) {
      dispatch(alertsMessages(error?.response?.data?.alert));
      throw Error();
    } finally {
      setLoadingService(false);
      setOpenModal((prevState) => ({ ...prevState, modalCreate: false }));
      getPermissions();
    }
  };

  const onFilterChange = async (dataFilter) => {
    if (dataFilter.items.length) {
      const { data } = await getAllUsersService({
        params: {
          [dataFilter.items[0].columnField]: dataFilter.items[0].value,
          page: pageState.page,
          size: pageState.pageSize,
        },
        isBearer: true,
      });

      setPageState((prev: TPageState) => ({
        ...prev,
        isLoading: false,
        data: data.data,
        total: data?.pageInfo?.totalRecords,
      }));
    }
  };

  useEffect(() => {
    getPermissions();
  }, [pageState.pageSize, pageState.page]);

  return (
    <>
      <Typography className="page-title" component="h1">
        ADMINISTRACIÓN DE PERMISOS
      </Typography>
      <div className={classes.tables_header}>
        <Button onClick={handleCreate} variant="contained">
          Agregar Permisos
        </Button>
      </div>

      <Table
        pageState={pageState}
        setPageState={setPageState}
        columns={columns}
        onFilterChange={onFilterChange}
      />

      <CustomModal
        modalTitle={
          rowData.isNewRecord ? "Crear Permiso" : "Actualizar Permiso"
        }
        setOpenModal={setOpenModal}
        openModal={openModal.modalCreate}
      >
        <FormPermissions
          loadingService={loadingService}
          onSubmit={onSubmit}
          rowData={rowData}
        />
      </CustomModal>

      <DialogConfirm
        onDelete={onDeleteRow}
        title="Eliminar Permiso"
        openModal={openModal.dialogConfirm}
        setOpenModal={setOpenModal}
        idRow={rowData.data.id}
      >
        ¿Esta seguro de eliminar este Permiso?
      </DialogConfirm>
    </>
  );
};

export default Permissions;
