const regex = {
  usuario: /^[A-Za-z1-90@.]+$/i,
  nombres: /^[A-Za-záéúíóàèìòùÑñ ]+$/i,
  cedula: /^[1-90() -]+$/i,
  email: /[A-Z0-9a-z._%+-]+@gmail\.com+/g,
  email2: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
  no_space: /^[\w\S]+$/g,
  no_special: /^[\w\s.:,"()áéúíóàèìòùÑñ-]+$/g,
  number: /^[0-9+]+/
};
const RULES = {
  requerido: {
    required: { value: true, message: 'Campo requerido' }
  },
  username: {
    required: { value: true, message: 'Campo requerido' },
    pattern: {
      value: regex.usuario,
      message: 'No se aceptan caracteres especiales ni espacio'
    }
  },
  no_required_no_special: {
    pattern: {
      value: regex.no_special,
      message: 'Solo se aceptan los siguientes caracteres: . : , " ( )'
    }
  },
  correo: {
    required: { value: true, message: 'Campo requerido' },
    pattern: {
      value: regex.email2,
      message: 'Correo no valido'
    }
  },
 

  cedula: {
    maxLength: { value: 8, message: 'Maximo 8 caracteres' },
    required: { value: true, message: 'Campo requerido' },
    pattern: { value: regex.cedula, message: 'Solo acepta numeros' }
  },
  contraseña: {
    minLength: { value: 6, message: 'Minimo 6 caracteres' },
    required: { value: true, message: 'Campo requerido' }
  },
  contraseña_admin: {
    minLength: { value: 6, message: 'Minimo 6 caracteres' },
    
  },
  codigo: {
    maxLength: { value: 10, message: 'Máximo 10 caracteres alfanuméricos' },
    required: { value: true, message: 'Campo requerido' }
  },
  no_space: {
    required: { value: true, message: 'Campo requerido' },
    pattern: {
      value: regex.no_space,
      message: 'No se aceptan espacios en blanco'
    }
  },
  no_special: {
    required: { value: true, message: 'Campo requerido' },
    pattern: {
      value: regex.no_special,
      message: 'Solo se aceptan los siguientes caracteres: . : , " ( )'
    }
  },
  number: {
    required: { value: true, message: 'Campo requerido' },
    pattern: {
      value: regex.number,
      message: 'Solo se aceptan numeros'
    }
  },
  phone: {
    required: { value: true, message: 'Campo requerido' },
    pattern: {
      value: regex.number,
      message: 'Solo se aceptan numeros'
    },
    minLength: { value: 7, message: 'Minimo 7 caracteres' }
  }
};

export { RULES };
