import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IParams } from 'interfaces/index';

/* interfaces */
import { RootState } from 'store';
/* Redux */
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { findValidConstancy } from 'reducers/userSlice';

export const useVerifing = () => {
  const { verifyData , isLoading } = useAppSelector(
    (state: RootState) => state.userReducer
  );
  const dispatch = useAppDispatch();
  const params = useParams();
  const { codigo } = params as IParams;

  useEffect(() => {
    dispatch(findValidConstancy(codigo as any)); // revision
  }, [codigo, dispatch]);

  return {
    verifyData,isLoading
  };
};
