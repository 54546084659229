import { request } from 'config/request';
import { API_NOMINA } from 'constants/index';

// get all profiles
export function getAllProfilesServices({ params, authRequire }: any) {
  const { page, size } = params as any;
  return request(`${API_NOMINA}/profile/table`, {
    method: 'get',
    authRequire,
    params: { page: page + 1, size }
  });
}
// create profiles
export function createProfilesServices({ payload, isBearer }: any) {
  return request(`${API_NOMINA}/profile/create`, {
    method: 'post',
    isBearer,
    data: payload
  });
}
// update profiles
export function updateProfilesServices({ payload, params, isBearer }: any) {
  return request(`${API_NOMINA}/profile/updateProfile/${params}/`, {
    method: 'put',
    data: payload,
    isBearer
  });
}
// delete profiles
export function deleteProfilesServices({ params, isBearer }: any) {
  return request(`${API_NOMINA}/profile/deleteProfile/${params}/`, {
    method: 'delete',
    isBearer
  });
}
