import React from "react";
/* @Mui */
import { Stack, Typography } from "@mui/material";
import { NAVIGATION } from "routes/navigation";
import { Link, useLocation } from "react-router-dom";

const Links = () => {
  const { pathname } = useLocation();

  return (
    <>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        justifyContent="space-between"
        alignItems={"center"}
      >
        {pathname !== NAVIGATION.RECOVER_PASSWORD && (
          <Link to={NAVIGATION.RECOVER_PASSWORD}>
            <Typography
              textAlign="center"
              component="p"
              fontSize={14}
              color="primary"
            >
              Recuperar contraseña
            </Typography>
          </Link>
        )}

        {pathname !== NAVIGATION.REGISTER && (
          <Link to={NAVIGATION.REGISTER}>
            <Typography
              textAlign="center"
              component="p"
              fontSize={14}
              color="primary"
            >
              ¿No estás registrado?
            </Typography>
          </Link>
        )}

        {pathname !== NAVIGATION.LOGIN && (
          <Link to={NAVIGATION.LOGIN}>
            <Typography
              textAlign="right"
              component="p"
              fontSize={14}
              color="primary"
            >
              Iniciar sesión
            </Typography>
          </Link>
        )}
      </Stack>
      <Stack>
        <Link to={NAVIGATION.VALIDATE_DOCUMENT}>
          <Typography
            textAlign="center"
            component="p"
            fontSize={14}
            color="primary"
          >
            Validar Constancia
          </Typography>
        </Link>
      </Stack>
    </>
  );
};

export default Links;
