import { Button, Grid, Skeleton } from '@mui/material';
import TextForm from 'components/TextForm';
import { memo } from 'react';


const ListGrid = ({ data = [], loading = false, spacing = 2, }: IListGrid) => {
  return (
    <Grid container spacing={spacing}>
      {data.map(({ title, content, variant, xs = 12, sm = 4, lg = 3, show }, idx) => {
        return show && <Grid key={idx} mt={2} item xs={xs} sm={sm} lg={lg}  >
          {loading && data
            ? <Skeleton variant={variant || 'text'} />
            : <TextForm titulo={title || '-'} texto={content || '-'} />}
        </Grid>

      }

      )}
    </Grid>
  );
};

/**
 * @method type content
 */
export type IListGrid = {
  data: any[];
  loading?: boolean;
  spacing?: number;
};
export default memo(ListGrid);
