import { FC, memo, useMemo } from "react";
import ListGrid from "components/ListGrid";
import { getFormatDateString } from "utils/helpers";

const DatosTrabajador: FC<IDatosTrabajadorPage> = ({ datos, loading }) => {
  const dataList = useMemo(
    () => [
      {
        title: "CÉDULA",
        content: `${datos?.nacionalidad}-${datos?.cedula}`,
        lg: 4,
        show: true,
      },
      {
        title: "NOMBRE COMPLETO",
        content: `${datos?.nombres}`,
        lg: 4,
        show: true,
      },
      {
        title: "FECHA DE NACIMIENTO",
        content: datos?.fechana
          ? getFormatDateString(datos.fechana, "dd-MM-yyyy")
          : "N/A",
        lg: 4,
        show: true,
      },
      {
        title: "CORREO",
        content: `${datos?.email}`,
        lg: 12,
        show: true,
      },
    ],
    [datos]
  );

  return (
    <>
      <ListGrid data={dataList} loading={loading} />
    </>
  );
};

/**
 * @method interface IDatosTrabajador
 */
interface IDatosTrabajador {
  email: string;
  nacionalidad: string;
  cedula: string;
  fechana: string;
  nombres: string;
}

export interface IDatosTrabajadorPage {
  datos: IDatosTrabajador;
  loading: boolean;
}

export default memo(DatosTrabajador);
