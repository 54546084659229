import { memo, useRef } from "react";

import { TextField } from "components/presentationals/Fields";
import ReCAPTCHA from "react-google-recaptcha";

/* hooks */
import { useFormHook } from "hooks/useFormHook";
import { useVerify } from "../hooks/useVerify";

/* @Mui */
import { Alert, Stack } from "@mui/material";

/* interfaces */
import { IPROPS } from "interfaces/index";
/* constans */
import { CustomButton } from "components/presentationals/CustomButton";
import { RULES, SITE_KEY } from "constants/index";
import { useWindowSize } from "hooks/useWindowSize";

const VerifyForm = () => {
  const DEFAULT_VALUES: IPROPS | undefined = {
    codigo: "",
  };
  let captcha: any = useRef(null);

  const { handleSubmit, errors, control, clearErrors, setError } =
    useFormHook(DEFAULT_VALUES);

  const { onSubmit } = useVerify(captcha, setError);

  const onChange = () => {
    clearErrors();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={3} alignItems="center">
        <TextField
          name="codigo"
          label="Código"
          control={control}
          variant="standard"
          rules={RULES.codigo}
          error={Boolean(errors.codigo)}
          errmsg={errors.codigo}
        />

        {useWindowSize().width < 420 ? (
          <ReCAPTCHA
            ref={captcha}
            onChange={onChange}
            sitekey={SITE_KEY}
            size="compact"
          />
        ) : (
          <ReCAPTCHA ref={captcha} onChange={onChange} sitekey={SITE_KEY} />
        )}

        {errors.captcha && (
          <Alert severity="warning">No esta validado por el captcha</Alert>
        )}
        <CustomButton
          label="ingresar"
          typeAction="submit"
          typeVariant="contained"
        />
      </Stack>
    </form>
  );
};

export default memo(VerifyForm);
