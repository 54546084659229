/* @Mui */
import { Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import theme from 'utils/theme';
import cintillo from 'static/img/CintilloBanner.png'

const myStyles = makeStyles({
  root: {
    backgroundColor: '#162f68',

  },
  images: {
    margin: '0px',

    '& > img': {
      width: '100%',
      maxHeight: '70px',

    }
  }
});

const LoginHeader: React.FC = () => {
  const classes = myStyles();
  return (
    <header>
      <Stack
        className={classes.root}
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent='center'
        alignItems='center'>
        <figure className={classes.images}>
          <img
            src={cintillo}
            alt='Logo Bolivariano'
          />
        </figure>
        { /*    <figure className={classes.images}>
          <img
            src='https://www.mincyt.gob.ve/wp-content/themes/twentyseventeen-master/assets/images/cintillos/cintillo_izq.png'
            alt='Logo Izquierda'
          />
        </figure> */}
      </Stack>
    </header>
  );
};

export default LoginHeader;
