import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { NAVIGATION } from "routes/navigation";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "reducers/authSlice";
import { getFirsLetter } from "utils/helpers";

const myStyles = makeStyles({
  AppBar: {
    boxShadow: "unset !important",
    borderBottom: "1px solid #e2e8f0",
  },
  menu: {
    padding: "10px !important",
  },
  name: {
    fontWeight: "normal",
    fontSize: "18px",
  },
  cargo: {
    fontWeight: "normal",
    fontSize: "14px",
  },
});

const BarApp: React.FC<{ handleOpen?: () => void }> = ({ handleOpen }) => {
  const classes = myStyles();
  const dispatch = useAppDispatch();

  const { isLoading } = useAppSelector((state) => state.userReducer);

  const { user } = useAppSelector((state) => state.authReducer);
  const { email } = user;

  const handleLogout = () => {
    dispatch(logout());
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [nombres, setNombres] = React.useState<string>("");
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = "primary-search-account-menu";

  React.useEffect(() => {
    if (email) {
      setNombres(email);
    }
  }, [email]);

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Box className={classes.menu}>
        {
          <MenuItem>
            <Stack spacing="0">
              {isLoading ? "" : <h3 className={classes.name}>{`${email}`}</h3>}
            </Stack>
          </MenuItem>
        }
        <Divider />
        <Link to={NAVIGATION.GET_PROFILE}>
          <MenuItem onClick={handleMenuClose}> Perfil</MenuItem>
        </Link>
        <Divider />
        <MenuItem onClick={() => handleLogout()}>Cerrar sesión</MenuItem>
      </Box>
    </Menu>
  );

  return (
    <>
      <AppBar position="static" color="transparent" className={classes.AppBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleOpen}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            size="large"
            edge="end"
            aria-label="cuenta"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <Avatar sx={{ bgcolor: "#172f69" }}>
              {!nombres.length ? "" : nombres[0][0] + nombres[1][0]}
            </Avatar>
          </IconButton>
        </Toolbar>
        {renderMenu}
      </AppBar>
    </>
  );
};
export default BarApp;
