import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { memo, useState } from "react";
import { Controller } from "react-hook-form";
import { ISelectFields } from "types";

const SelectForm: React.FC<ISelectFields> = ({
  name = "",
  control,
  variant = "standard",
  label = "label",
  defaultValue = "",
  options = [],
  itemId = "id",
  itemLabel = "name",
  error = false,
  rules = {},
  handleChange = () => {
    return null;
  },
  readOnly = false,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, ref, value } }) => (
        <FormControl fullWidth>
          <InputLabel
            sx={{
              top: "5px",
            }}
            id="Label-name"
          >
            {label}
          </InputLabel>
          <Select
            labelId={`${label}-id`}
            label={label}
            id={`${name}_id`}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            onChange={(e) => {
              onChange(e);
              handleChange(e, name);
            }}
            name={name}
            value={value}
            variant={variant}
            inputProps={{ readOnly }}
            style={{ width: "100%" }}
            error={error ? true : false}
            fullWidth
            inputRef={ref}
            disabled={disabled}
          >
            {options.map((e: Record<string, unknown>, index: number) => {
              return (
                <MenuItem value={e[itemId] as string} key={index}>
                  {`${e[itemLabel]}`}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    />
  );
};

export type TSelectOptions = {
  id: string;
  name: string;
};
export default memo(SelectForm);
