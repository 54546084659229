import  { useState } from 'react'
import { Button, Typography } from '@mui/material'
import { CustomModal } from 'components/presentationals/CustomModal';
import { Table } from 'components/presentationals/Table';
import { Helmet } from 'react-helmet-async'
import { myStylesTablesPages } from 'static/styles-mui';
import { TPageState } from 'types';
import Form14100 from './components/Form14100';

import { useAppSelector } from "hooks/useRedux";
const Module14100 = () => {
  const { user : {email} } = useAppSelector((state) => state.authReducer);
  const { userData  } = useAppSelector((state) => state.userReducer);
  const classes = myStylesTablesPages();
  const [pageState, setPageState] = useState<TPageState>({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
  });
  const [loadingService, setLoadingService] = useState(false);
  const [openModal, setOpenModal] = useState({
    dialogConfirm: false,
    modalCreate: false,
  });


  const columns = [
    { field: "name", headerName: "Nombre", width: 200 },
    { field: "ci", headerName: "Cedula", width: 150, },
    { field: "codigo", headerName: "Codigo solicitud", width: 120,  },
    { field: "fechaSolicitud", headerName: "Fecha solicitud", width: 120,  },
    { field: "description", headerName: "Motivo solicitud", minWidth: 100, flex:1  },
    { field: "status", headerName: "Estatus", width: 150,  },
  ];



  const handleCreate = () => {
    setOpenModal((prev) => ({ ...prev, modalCreate: true }));
  };

  const onSubmit = async (data: any) => {
    try {
     /*  setLoadingService(true);
      const id = data.id;
      delete data.id;
      if (rowData.isNewRecord) {
        (await createPermissionsServices({
          payload: data,
          isBearer: true,
        })) as TGenericResponse;
        dispatch(
          alertsMessages({
            name: "success",
            message: "¡Se ha creado un permiso exitosamente",
          })
        );
      } else {
        (await updatePermissionsServices({
          payload: data,
          params: id,
          isBearer: true,
        })) as TGenericResponse;
        dispatch(
          alertsMessages({
            name: "success",
            message: "¡Se ha actualizado un permiso exitosamente",
          })
        );
      } */
    } catch (error: any) {
    /*   dispatch(alertsMessages(error?.response?.data?.alert));
      throw Error(); */
    } finally {
     /*  setLoadingService(false);
      setOpenModal((prevState) => ({ ...prevState, modalCreate: false }));
      getPermissions(); */
    }
  };


  return (
   <>
      <Helmet>
        <title>ME - 14100</title>
      </Helmet>

   <section>
   <Typography className="page-title" component="h1">
        SOLICITUD DE COTIZACIÓN 14100
      </Typography>

      <div className={classes.tables_header}>
        <Button onClick={handleCreate} variant="contained">
          solicitar 14100
        </Button>
      </div>

      <Table
        pageState={pageState}
        setPageState={setPageState}
        columns={columns}
    
      />
   </section>

   <CustomModal
        modalTitle="Solicitar cotización 14100" 
        setOpenModal={setOpenModal}
        openModal={openModal.modalCreate}
      >
        <Form14100
          onSubmit={onSubmit}
          loadingService={loadingService}
          data = {{
            email: email,
            ...userData[0]
          }}
        />
      </CustomModal>

   </>
  )
}

export default Module14100