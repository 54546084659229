/* @Mui */

import { ThemeProvider } from "@mui/material/styles";

/* hooks */
import { useAlert } from "hooks";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";

import { Route, Routes, useNavigate } from "react-router-dom";
/* Redux */
import { reloadingBrowser } from "reducers/authSlice";
import { userDataWorker } from "reducers/userSlice";

/* @Utils */
import theme from "utils/theme";
import "static/styles/App.scss";
import { routes } from "routes";
import { TRoute } from "types";
import { typeRouteEnum } from "utils/enums";
import NAVIGATION from "routes/navigation";
import MainLayout from "components/presentationals/MainLayout/MainLayout";
import PublicLayout from "components/presentationals/PublicLayout/PublicLayout";
import { Page404 } from "components/presentationals/NotFound";
import { localToken } from "constants/index";
import { searchItemLocal } from "utils/helpers";

const App: React.FC = () => {
  const { openAlert } = useAlert();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const accessPublic = [
    typeRouteEnum.PUBLIC,
    typeRouteEnum.COMPOSED,
  ] as string[];
  const accessPrivate = [
    typeRouteEnum.PRIVATE,
    typeRouteEnum.COMPOSED,
  ] as string[];

  const token = searchItemLocal(localToken);

  const { alertMessages } = useAppSelector((state) => state.appReducer);
  const { user, isAuthenticated } = useAppSelector(
    (state) => state.authReducer
  );

  /* Verificar token */
  useEffect(() => {
    dispatch(reloadingBrowser());

  }, [dispatch]);

  useEffect(() => {
    if (window.location.pathname === "/") {
      navigate(NAVIGATION.LOGIN);
    }
  }, [navigate]);

  useEffect(() => {
    if (token) {
      dispatch(userDataWorker({}))

    }
  }, [dispatch, user, isAuthenticated]);

  /* Alertas */
  useEffect(() => {
    if (!isEmpty(alertMessages))
      openAlert(alertMessages.message, alertMessages.name);
  }, [openAlert, alertMessages]);

  return (
    <>

      <ThemeProvider theme={theme}>
        <Routes>
          {/* Rutas privadas */}
          <Route element={<MainLayout />}>
            {routes
              .filter((route: TRoute) =>
                accessPrivate.includes(route?.typeRoute as string)
              )
              .map((route: TRoute) => (
                <Route

                  key={route.path}
                  path={route.path}
                  element={<route.component />}
                />
              ))}
          </Route>
          {/* Rutas publicas */}
          <Route element={<PublicLayout />}>
            {routes
              .filter((route: TRoute) =>
                accessPublic.includes(route?.typeRoute as string)
              )
              .map((route: TRoute) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<route.component />}
                />
              ))}
          </Route>
          {/* Rutas no encontradas */}
          <Route
            path="*"
            element={window.location.pathname !== "/" && <Page404 />}
          />
        </Routes>
      </ThemeProvider>
    </>
  );
};

export default App;
