import { TMenuRoutes, TRoute } from "types";
import { typeRouteEnum } from "utils/enums";
import { NAVIGATION } from "./navigation";

import Arc from "pages/arc/Arc";
import Benefits from "pages/benefits/Benefits";
import Construccion from "pages/construccion/Construccion";
import Dash from "pages/dashboard/Dash";
import Documents from "pages/documents/Documents";
import Evaluations from "pages/evaluations/Evaluations";
import Module14100 from "pages/14100/Module14100";
import Login from "pages/login/Login";
import Modules from "pages/modules/Modules";
import Permissions from "pages/permissions/Permission";
import Profile from "pages/profile/Profile";
import Profiles from "pages/profiles/Profiles";
import Receipt from "pages/receipt/Receipt";
import Recover from "pages/recover/Recover";
import Register from "pages/register/Register";
import UpdatePassword from "pages/updatePassword/UpdatePassword";
import Users from "pages/users/Users";
import VerifingDocument from "pages/verifingDocument/VerifingDocument";
import VerifyDocument from "pages/verifyDocument/VerifyDocument";
import ManageDocuments from "pages/manageDocuments/ManageDocuments";

export const routes: TRoute[] = [
  {
    label: "Principal",
    path: NAVIGATION.HOME,
    component: Dash,
    typeRoute: typeRouteEnum.PRIVATE,
  },

  {
    label: "Validar Documento",
    path: NAVIGATION.VALIDATE_DOCUMENT,
    component: VerifyDocument,
    typeRoute: typeRouteEnum.PUBLIC,
  },
  {
    label: "Validando Documento",
    path: NAVIGATION.VALIDATE_DOCUMENT_BY_CODE,
    component: VerifingDocument,
    typeRoute: typeRouteEnum.PUBLIC,
  },
  {
    label: "Construccion",
    path: NAVIGATION.CONSTRUCTION,
    component: Construccion,
    typeRoute: typeRouteEnum.PUBLIC,
  },
  {
    label: "Registrarse",
    path: NAVIGATION.REGISTER,
    component: Register,
    typeRoute: typeRouteEnum.PUBLIC,
  },
  {
    label: "iniciar-sesion",
    path: NAVIGATION.LOGIN,
    component: Login,
    typeRoute: typeRouteEnum.PUBLIC,
  },
  {
    label: "Recuperar",
    path: NAVIGATION.RECOVER_PASSWORD,
    component: Recover,
    typeRoute: typeRouteEnum.PUBLIC,
  },
  {
    label: "Recuperar",
    path: NAVIGATION.UPDATE_PASSWORD,
    component: UpdatePassword,
    typeRoute: typeRouteEnum.PUBLIC,
  },

  {
    label: "Perfil",
    path: NAVIGATION.GET_PROFILE,
    component: Profile,
    typeRoute: typeRouteEnum.PRIVATE,
  },
  {
    label: "Recibo",
    path: NAVIGATION.GET_RECEIPT,
    component: Receipt,
    typeRoute: typeRouteEnum.PRIVATE,
  },
  {
    label: "Arc",
    path: NAVIGATION.GET_ARC,
    component: Arc,
    typeRoute: typeRouteEnum.PRIVATE,
  },
  {
    label: "usuarios",
    path: NAVIGATION.GET_USERS,
    component: Users,
    typeRoute: typeRouteEnum.PRIVATE,
  },
  {
    label: "administracion de modulos",
    path: NAVIGATION.MODULES,
    component: Modules,
    typeRoute: typeRouteEnum.PRIVATE,
  },
  {
    label: "actualizacion de sello y firma",
    path: NAVIGATION.MANAGE_DOCUMENTS,
    component: ManageDocuments,
    typeRoute: typeRouteEnum.PRIVATE,
  },
  {
    label: "prestaciones sociales",
    path: NAVIGATION.BENEFITS,
    component: Benefits,
    typeRoute: typeRouteEnum.PRIVATE,
  },
  {
    label: "administracion de perfiles",
    path: NAVIGATION.MODULES_PROFILES,
    component: Profiles,
    typeRoute: typeRouteEnum.PRIVATE,
  },
  {
    label: "administracion de permisos",
    path: NAVIGATION.MODULES_PERMISSIONS,
    component: Permissions,
    typeRoute: typeRouteEnum.PRIVATE,
  },
  {
    label: "administracion de documentos",
    path: NAVIGATION.ADMIND_DOCS,
    component: Documents,
    typeRoute: typeRouteEnum.PRIVATE,
  },
  {
    label: "evaluaciones",
    path: NAVIGATION.EVALUATIONS,
    component: Evaluations,
    typeRoute: typeRouteEnum.PRIVATE,
  },
  {
    label: "14100",
    path: NAVIGATION.MODULE_14100,
    component: Module14100,
    typeRoute: typeRouteEnum.PRIVATE,
  },
];

export const MenuRoutes: TMenuRoutes[] = [
  {
    id: 1,
    name: "Inicio",
    path: "/inicio",
    icon: "DashboardIcon",
    display: "principal",
  },
  {
    id: 2,
    name: "Arc-v",
    path: "/inicio/arc",
    icon: "TextSnippetIcon",
    display: "principal",
  },
  // {
  //   id: 3,
  //   name: 'Recibo de Pago',
  //   path: '/inicio/recibo',
  //   icon: 'home',
  //   display: 'principal'
  // },
  {
    id: 3,
    name: "Evaluaciones",
    path: "/inicio/evaluaciones",
    icon: "TaskIcon",
    display: "principal",
  },
];
