import React, { memo } from 'react';
import { IVALIDO } from 'interfaces';
import { Stack, Grid } from '@mui/material';
import TextForm from 'components/TextForm';
import { makeStyles } from '@mui/styles';

const myStyles = makeStyles({
  h2: {
    fontSize: '17px',
    color: '#424242'
  }
});

const Valido = ({ datos }: { datos: IVALIDO }) => {
  const classes = myStyles();

  const {
    codigo,
    cedula,
    fecha_ven,
    nacionalidad,
    nombre,
    monto,
    dcargo,
    ddpendencia,
    tipo_persona
  } = datos;

  return (
    <Stack>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent='space-between'>
        <h2 className={classes.h2}>{`Código: ${codigo}`}</h2>
        <h2 className={classes.h2}>{`Fecha de vencimiento: ${fecha_ven}`}</h2>
      </Stack>

      <Grid container>
        <Grid mt={2} item xs={12} sm={12}>
          <TextForm titulo='NOMBRE' texto={`${nombre} `} />
        </Grid>

        <Grid mt={2} item xs={12} sm={6}>
          <TextForm titulo='CEDULA' texto={`${nacionalidad}-${cedula}`} />
        </Grid>
        <Grid mt={2} item xs={12} sm={6}>
          <TextForm titulo='TIPO DE PERSONAL' texto={`${tipo_persona}`} />
        </Grid>
        <Grid mt={2} item xs={12} sm={6}>
          <TextForm titulo='CARGO' texto={`${dcargo}`} />
        </Grid>
        <Grid mt={2} item xs={12} sm={6}>
          <TextForm titulo='INGRESO MENSUAL' texto={`Bs. ${monto}`} />
        </Grid>
        <Grid mt={2} item xs={12} sm={12}>
          <TextForm titulo='DEPENDENCIA' texto={`${ddpendencia}`} />
        </Grid>
        <Grid mt={2} item xs={12}>
          <p>
            Los datos que se muestran aqui deben ser los mismos que estan en la constancia de trabajo
          </p>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default memo(Valido);
