import { Button, Typography } from "@mui/material";

const ManageDocuments = () => {
  return (
    <>
      <Typography className="page-title" component="h1">
        ACTUALIZAR SELLO Y FIRMA
      </Typography>
      <div>
        <Button variant="contained">sello y firma</Button>
      </div>
    </>
  );
};

export default ManageDocuments;
