// @PREXIs
export const PREFIX_DASHBOARD = "/inicio";
export const PREFIX_ADMIN = "/administracion";

// @NAVIGATION
export const NAVIGATION = {
  HOME: PREFIX_DASHBOARD,
  LOGIN: `/iniciar-sesion`,

  // Validardocumento
  VALIDATE_DOCUMENT: `/validardocumento`,
  VALIDATE_DOCUMENT_BY_CODE: `/validardocumento/:codigo`,

  // CONSTRUCTION
  CONSTRUCTION: "/construccion",

  // REGISTER
  REGISTER: "/registrar",

  // PASSWORD
  RECOVER_PASSWORD: "/recuperar-clave",
  UPDATE_PASSWORD: "/actualizar-clave/:token",

  // PROFILE
  GET_PROFILE: `${PREFIX_DASHBOARD}/perfil`,

  // RECEIPT
  GET_RECEIPT: `${PREFIX_DASHBOARD}/recibo`,

  // ARC
  GET_ARC: `${PREFIX_DASHBOARD}/arc`,

  //Benefits
  BENEFITS: `${PREFIX_DASHBOARD}/prestaciones-sociales`,
  // Modules users

  GET_USERS: `${PREFIX_ADMIN}/usuarios`,
  // Manage Documents
  MANAGE_DOCUMENTS: `${PREFIX_ADMIN}/actualizar-documentos`,
  // Modules
  MODULES: `${PREFIX_ADMIN}/modulos`,
  // Modules of perfiles
  MODULES_PROFILES: `${PREFIX_ADMIN}/administracion-de-perfiles`,
  // Modules of perfiles
  MODULES_PERMISSIONS: `${PREFIX_ADMIN}/administracion-de-permisos`,
  // Modules Docs
  ADMIND_DOCS: `${PREFIX_ADMIN}/administracion-de-documentos`,
  // Evaluaciones
  EVALUATIONS: `${PREFIX_DASHBOARD}/evaluaciones`,

  // 14100
  MODULE_14100: `${PREFIX_DASHBOARD}/14100`,
};
export default NAVIGATION;
