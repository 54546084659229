/**
 *
 * @method enum PERMISSIONS
 */
export enum PERMISSIONS {
  DISABLED_BUTTON_CONSTANCY = "DESHABILITAR_BOTON_CONSTANCIA",
  DISABLED_BUTTON_RECEIPT = "DESHABILITAR_BOTON_RECIBO",
  DISABLED_BUTTON_ARC = "DESHABILITAR_BOTON_ARC",
  SHOW_BUTTON_CONSTANCY = "MOSTRAR_BOTON_CONSTANCIA",
  SHOW_BUTTON_RECEIPT = "MOSTRAR_BOTON_RECIBO",
  SHOW_BUTTON_ARC = "MOSTRAR_BOTON_ARC",
  SHOW_BUTTON_PERMISIONS = "MOSTRAR_BOTON_PERMISOS",
  SHOW_RELATIONS_PERMISSION = "MOSTRAR_RELACIONES_PERMISOS",
  SHOW_RELATIONS_ROLES = "MOSTRAR_RELACIONES_ROLES",
  DISABLE_LIMIT = "DESHABILITAR_LIMITE",
}
