import { Alert } from "@mui/material";
const AlertInfo = ({
  texto = "",
  children = null,
}: {
  texto?: string;
  children?: any;
}) => {
  return (
    <Alert
      sx={{ fontSize: "11px", marginTop: "6px", padding: "0px 16px" }}
      severity="info"
    >
      <p>
        {texto}
        {children}
      </p>
    </Alert>
  );
};

export default AlertInfo;
