import { Alert, Button, CircularProgress, Grid } from '@mui/material'
import ListGrid from 'components/ListGrid';
import { TextField } from 'components/presentationals/Fields';
import { Form } from 'components/presentationals/Formularies';
import { RULES } from 'constants/index';
import { useFormHook } from 'hooks/useFormHook';
import React, { useMemo } from 'react'
import { getFormatDateString, getTimeWork } from 'utils/helpers';

const Form14100 : React.FC<FormDataProps> = ({onSubmit,data, loadingService }) => {
    const { control, errors, handleSubmit } = useFormHook(data);


     const dataList = useMemo(
        () => [
          {
            title: "CÉDULA",
            content: `${data?.identidad}`,
            lg: 4,
            show: true,
          },
          {
            title: "NOMBRE COMPLETO",
            content: `${data?.nombre}`,
            lg: 4,
            show: true,
          },
    
          {
            title: "FECHA DE NACIMIENTO",
            content: `${data?.fecha_nacimiento ? getFormatDateString(data?.fecha_nacimiento, 'dd-MM-yyyy') : 'N/A'}`,
            lg: 4,
            show: true,
          },
          {
            title: "CORREO",
            content: `${data?.email}`,
            lg: 4,
            show: true,
          },
          {
            title: "FECHA DE INGRESO",
            content: `${data?.fecha_ingreso}`,
            lg: 4,
            show: true,
          },
          {
            title: "TIEMPO DE SERVICIO",
            content: getTimeWork(data?.fecha_ingreso) ?? "",
            lg:4,
            show: true,
          },
        ],
        [data]
      ); 
  return (
   <section>
        <ListGrid data={dataList}  />
        <Form onSubmit={onSubmit} handleSubmit={handleSubmit}>
        <Grid container alignItems="flex-end" spacing={3}>
        <Grid item xs={12}>
        <TextField
            name="description"
            type="textarea"
            label="Razon de la solicitud"
            variant="standard"
            control={control}
            multiline
            rules={RULES.requerido}
            error={Boolean(errors.description)}
            errmsg={errors.description}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="telefono"
            type="text"
            label="Telefono"
            variant="standard"
            control={control}
       
            rules={RULES.requerido}
            error={Boolean(errors.telefono)}
            errmsg={errors.telefono}
          />
        </Grid>
      </Grid>

      <Button
        fullWidth
        variant="contained"
        type="submit"
        disabled={loadingService}>
        {loadingService ? <CircularProgress size={24} /> : 'Enviar Solicitud'}
      </Button>

      <Alert severity="info">Debe de asegurarse de que todos los datos esten completos y correctos antes de enviar la solicitud, en caso de error en sus datos personales debe de contactarse con recursos humanos  </Alert>


        </Form>


     
   </section>
  )
}

export interface FormDataProps {
    onSubmit: (data: FormDataProps) => void
    data: Record<string, string| number>
    loadingService: boolean
}

export default Form14100