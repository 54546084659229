import React, { memo, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
/* interfaces */
import IPage from "../../interfaces/page";

/* MUI */
import { Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

/* SWIPER */
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import SearchWorker from "./components/SearchWorker";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { IUserConstanciaData } from "interfaces";
import { alertsMessages } from "reducers/appSlice";
import { UniqueUser } from "services/user";
import { TUseFormHookProps } from "types";

import ListaCargos from "pages/dashboard/components/ListaCargos";

const myStyles = makeStyles({
  root: {
    display: "grid !important",

    gap: "20px",
  },
});

const Documents: React.FC<IPage> = () => {
  const classes = myStyles();
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.userReducer);
  const [worker, setWorker] = useState<
    Partial<IUserConstanciaData> | null | any
  >([]);
  const [loadingService, setLoadingService] = useState({
    loadingSearch: false,
    loadingConstancy: false,
    loadingReceipt: false,
    loadingArc: false,
  });

  const onSubmitConstancy = async (data: TUseFormHookProps) => {
    try {
      setLoadingService((prevState) => ({ ...prevState, loadingSearch: true }));
      const response = await UniqueUser({ authRequire: true, payload: data });

      const datos = response?.data;
      if (!response?.data[0]) {
        return dispatch(
          alertsMessages({
            message: "Trabajador no encontrado",
            name: "Error",
          })
        );
      }
      setWorker(datos);
    } catch (error: any) {
      dispatch(alertsMessages(error?.response?.data?.alert));
      throw Error();
    } finally {
      setLoadingService((prevState) => ({
        ...prevState,
        loadingSearch: false,
      }));
    }
  };

  useEffect(() => {
    console.log("worker", worker);
  }, [worker]);

  return (
    <>
      <Helmet>
        <title>MPPE - ARC</title>
      </Helmet>
      <section>
        <Container maxWidth="lg" className={classes.root}>
          <Container maxWidth="sm" className={classes.root}>
            <Typography className="page-title" component="h1">
              ADMINISTRAR DOCUMENTOS
            </Typography>
            <SearchWorker
              onSubmit={onSubmitConstancy}
              loadingService={loadingService.loadingSearch as boolean}
            />{" "}
            <section>
              <Swiper
                navigation={true}
                modules={[Pagination, Navigation]}
                // className={classes.main}
                style={{ maxWidth: "554px" }}
                grabCursor={false}
                hashNavigation={false}
                simulateTouch={false}
              >
                {worker.map((datos, idx) => {
                  return (
                    <SwiperSlide>
                      <ListaCargos
                        key={idx}
                        datos={datos}
                        loading={isLoading}
                        activeARC={true}
                        multipleReceipt={worker.length > 1}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </section>
          </Container>
        </Container>
      </section>
    </>
  );
};

export default memo(Documents);
