/* @componentes */
import React, { memo } from 'react';
import RecoverForm from './components/RecoverForm';
import Links from 'components/presentationals/Links/Links';
import { Helmet } from 'react-helmet-async';
/* @Mui */
import { Stack, Typography } from '@mui/material';



const Recover = () => {

  return (
    <>
      <Helmet>
        <title>ME - Recuperación de contraseña</title>
      </Helmet>{' '}
      <Stack p={4} spacing={2}>
        <Typography className="page-title-login" component='h1'>
          Recuperar Contraseña
        </Typography>
        <RecoverForm />
        <Links />
      </Stack>
    </>
  );
};

export default memo(Recover);
