import { request } from "config/request";
import { API_EVALUACIONES } from "constants/index";
import { iRequest } from "interfaces/api";

export const getPeriods = ({ authRequire }: iRequest) => {
  return request(`${API_EVALUACIONES}/periodos?estado=true&limit=10&page=1`, {
    method: "get",
    authRequire,
  });
};

/* Evaluados */

export const getEvaluated = ({ authRequire, params }: iRequest) => {
  return request(`${API_EVALUACIONES}/evaluados`, {
    method: "get",
    authRequire,
    params: params,
  });
};

/* Evaluaciones */

export const getEvaluations = ({ params, authRequire }: iRequest) => {
  return request(`${API_EVALUACIONES}/evaluaciones`, {
    method: "get",
    authRequire,
    params: params,
  });
};

export const putEvaluations = ({ params, payload, authRequire }: iRequest) => {
  return request(`${API_EVALUACIONES}/evaluaciones/${params?.id}`, {
    method: "patch",
    authRequire,
    data: {
      estado_id: payload?.estado_id,
    },
  });
};

/* Odis */

export const getOdis = ({ params, authRequire }: iRequest) => {
  return request(`${API_EVALUACIONES}/odis`, {
    method: "get",
    authRequire,
    params: params,
  });
};

export const putOdis = ({ params, authRequire, payload }: iRequest) => {
  return request(`${API_EVALUACIONES}/odis/${params?.id}`, {
    method: "patch",
    authRequire,
    data: {
      estado: payload?.estado,
    },
  });
};
