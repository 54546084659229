/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Typography } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";

import { CustomModal } from "components/presentationals/CustomModal";
import { DialogConfirm } from "components/presentationals/DialogConfirm";
import { ActionsButtonsTable, Table } from "components/presentationals/Table";
import { useAlert } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { createModulesServices, deleteModulesServices } from "services/modules";
import { getAllUsersService, updateUserServices } from "services/user";
import { TGenericResponse, TPageState } from "types";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { alertsMessages } from "reducers/appSlice";
import { useAppDispatch } from "../../hooks/useRedux";
import FormUsers from "./components/FormUsers";
const initialDataRow = {
  id: "",
  nacionalidad: "",
  cedula: "",
  email: "",
  password: "",
  intentos_fallidos: 0,
  isdelete: false,
};

const Users = () => {
  const dispatch = useAppDispatch();
  const { openAlert } = useAlert();

  const [loadingService, setLoadingService] = useState(false);
  const [rowData, setRowData] = useState({
    data: initialDataRow,
    isNewRecord: true,
  });
  const [pageState, setPageState] = useState<TPageState>({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 10,
  });
  const [openModal, setOpenModal] = useState({
    dialogConfirm: false,
    modalCreate: false,
  });

  const getUsers = async () => {
    try {
      setPageState((prev: TPageState) => ({ ...prev, isLoading: true }));
      const { data } = await getAllUsersService({
        params: {
          page: pageState.page,
          size: pageState.pageSize,
        },
        authRequire: true,
      });

      setPageState((prev: TPageState) => ({
        ...prev,
        isLoading: false,
        data: data.data,
        total: data?.pageInfo?.totalRecords,
      }));
    } catch (err: any) {
      dispatch(alertsMessages(err?.response?.data?.alert));
      throw Error();
    }
  };

  /* 
  const handleCreate = () => {
    setOpenModal({ dialogConfirm: false, modalCreate: true });
    setRowData({ data: initialDataRow, isNewRecord: true });
  }; */

  const handleEditRow = (dataRow: GridCellParams) => () => {
    setOpenModal({ dialogConfirm: false, modalCreate: true });
    setRowData({ data: dataRow.row, isNewRecord: false });
  };

  const handleDeleteRow = (dataRow: GridCellParams) => () => {
    setOpenModal({ dialogConfirm: true, modalCreate: false });
    setRowData({ data: dataRow.row, isNewRecord: false });
  };

  const columns: GridColDef[] = useMemo(
    () => [
      { field: "nacionalidad", headerName: "Nac", width: 35 },
      { field: "cedula", headerName: "Cedula", width: 100 },
      {
        field: "email",
        headerName: "Correo del Usuario",
        minWidth: 250,
        flex: 1,
      },
      { field: "failedAttempts", headerName: "Intentos", width: 70 },
      {
        field: "isdelete",
        headerName: "Estado",
        minWidth: 120,
        renderCell: ({ row }) => {
          return (
            <Stack direction="row" spacing={1}>
              {row.isdelete ? (
                <CheckCircleOutlineIcon color="success" />
              ) : (
                <DoDisturbIcon color="error" />
              )}
              <p>{row.isdelete ? "Activo" : "Inactivo"}</p>
            </Stack>
          );
        },
      },
      {
        field: "actions",
        headerName: "Acciones",
        renderCell: (params) => (
          <ActionsButtonsTable
            handleEditRow={handleEditRow(params)}
            // handleDeleteRow={handleDeleteRow(params)}
          />
        ),
        sortable: false,
        width: 100,
        headerAlign: "center",
        filterable: false,
        align: "center",
        disableColumnMenu: true,
        disableReorder: true,
      },
    ],
    []
  );

  const onDeleteRow = async (id: unknown) => {
    try {
      setLoadingService(true);
      (await deleteModulesServices({
        params: id as any,
        authRequire: true,
      })) as any;
      openAlert("Registro Eliminado Existosamente", "success");
    } catch (err) {
      openAlert("A ocurrido un error", "error");
    } finally {
      setLoadingService(false);
      getUsers();
      setOpenModal((prevState) => ({ ...prevState, dialogConfirm: false }));
    }
  };

  const onSubmit = async (data: any) => {
    try {
      setLoadingService(true);

      if (!data.password) {
        delete data.password;
      }
      const cedula = data.cedula;
      delete data.id;
      delete data.users_profiles;

      if (rowData.isNewRecord) {
        (await createModulesServices({
          payload: data,
          isBearer: true,
        })) as TGenericResponse;
        openAlert("Se ha registrado existosamente", "success");
      } else {
        (await updateUserServices({
          payload: data,
          params: cedula,
          isBearer: true,
        })) as TGenericResponse;
        openAlert("Se ha actualizado existosamente", "success");
      }
    } catch (error: any) {
      dispatch(alertsMessages(error?.response?.data?.alert));
      throw Error();
    } finally {
      setLoadingService(false);
      setOpenModal((prevState) => ({ ...prevState, modalCreate: false }));
      getUsers();
    }
  };

  const onFilterChange = async (dataFilter) => {
    if (dataFilter.items.length) {
      const { data } = await getAllUsersService({
        params: {
          [dataFilter.items[0].columnField]: dataFilter.items[0].value,
          page: pageState.page,
          size: pageState.pageSize,
        },
        authRequire: true,
      });

      setPageState((prev: TPageState) => ({
        ...prev,
        isLoading: false,
        data: data.data,
        total: data?.pageInfo?.totalRecords,
      }));
    }
  };

  useEffect(() => {
    getUsers();
  }, [pageState.pageSize, pageState.page]);

  return (
    <>
      <Typography className="page-title" component="h1">
        ADMINISTRACIÓN DE USUARIO
      </Typography>

      <Table
        pageState={pageState}
        setPageState={setPageState}
        columns={columns}
        onFilterChange={onFilterChange}
      />

      <CustomModal
        modalTitle={
          rowData.isNewRecord ? "Crear Usuario" : "Actualizar Usuario"
        }
        setOpenModal={setOpenModal}
        openModal={openModal.modalCreate}
      >
        <FormUsers
          loadingService={loadingService}
          onSubmit={onSubmit}
          rowData={rowData}
        />
      </CustomModal>

      <DialogConfirm
        onDelete={onDeleteRow}
        title="Eliminar usuario"
        openModal={openModal.dialogConfirm}
        setOpenModal={setOpenModal}
        idRow={rowData.data.id}
      >
        ¿Esta seguro de eliminar este modulo?
      </DialogConfirm>
    </>
  );
};

export default Users;
