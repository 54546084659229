import { Switch } from "@mui/material";
import { memo, useState } from "react";

const SwitchSimple = ({
  name = "",
  handleChecked,
  checked = false,
  disabled = false,
}) => {
  const [activate, setActivate] = useState(checked);
  const [loading, setLoading] = useState(false);
  return (
    <Switch
      checked={activate}
      onChange={(e) => {
        handleChecked(e, setActivate, setLoading);
      }}
      name={name}
      disabled={loading || disabled}
    />
  );
};
export default memo(SwitchSimple);
