import { TextField } from "components/presentationals/Fields";
import { Form } from "components/presentationals/Formularies";
import PasswdField from "components/presentationals/Fields/PasswdField";
import { NATIONALITY, RULES } from "constants/index";

import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import CheckField from "components/presentationals/Fields/CheckField";
import SwitchField from "components/presentationals/Fields/SwitchField";
import SelectForm from "components/presentationals/Fields/TextFieldSelect";
import { useFormHook } from "hooks/useFormHook";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import React, { useEffect, useState } from "react";
import { alertsMessages } from "reducers/appSlice";
import { getAllProfilesServices } from "services";
import { TFormProps, TOptionsChecked } from "types";
import { TCheckedState } from "types/common";
import { PERMISSIONS } from "utils/enums";
import { getPermiso } from "utils/helpers";

const FormUsers: React.FC<TFormProps> = ({
  onSubmit,
  loadingService,
  rowData,
}) => {
  const dispatch = useAppDispatch();
  const { data } = rowData;
  const { user } = useAppSelector((state) => state.authReducer);
  const { control, errors, handleSubmit } = useFormHook(data);
  const objCheckedDefaultProfiles = data.users_profiles
    ? data.users_profiles.reduce((acc, el) => {
      if (!acc[el.profile_id]) {
        acc[el.profile_id] = true;
      }
      return acc;
    }, {} as TCheckedState)
    : {};

  const [profiles, setProfiles] = useState<TOptionsChecked[]>([]);
  const [checked, setChecked] = useState(data.isdelete);
  const [checkedProfiles, setCheckedProfiles] = useState<TCheckedState>(
    objCheckedDefaultProfiles
  );

  const handleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    stateChecked: TCheckedState,
    setCheckedState: React.Dispatch<React.SetStateAction<TCheckedState>>
  ) => {
    setCheckedState({
      ...stateChecked,
      [event.target.value]: event.target.checked,
    });
    const currentId = event.target.value;
    const currentChecked = event.target.checked;

    const isIntersected = Object.keys(stateChecked).some(
      (el) => Number(el) === Number(currentId)
    );

    if (!isIntersected && currentChecked) {
      /* Insertar */
      setCheckedState((prev) => ({
        ...prev,
        [currentId]: currentChecked,
      }));
    } else {
      const newCheckdList = Object.keys({ ...stateChecked })
        .filter((el) => el !== currentId)
        .reduce((acc, el) => {
          if (!acc[el]) {
            acc[el] = true;
          }
          return acc;
        }, {} as Record<string | number, boolean>);

      setCheckedState(newCheckdList);
    }
  };
  const handleChecked = ({
    target: { checked },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(checked);
  };

  const getProfiles = async () => {
    try {
      const { data } = await getAllProfilesServices({
        params: {
          page: 0,
          size: 100,
        },
      });
      setProfiles(data.data as TOptionsChecked[]);
    } catch (error: any) {
      dispatch(alertsMessages(error?.response?.data?.alert));
      throw Error();
    }
  };

  const preSubmit = async (data: unknown) => {
    const profilesToArray = Object.keys(checkedProfiles);

    if (getPermiso(user.permissions, PERMISSIONS.SHOW_RELATIONS_ROLES)) {


      if (!profilesToArray.length) {
        dispatch(alertsMessages({ message: 'Debe seleccionar al menos un rol', name: 'error' }));
        return;
      }
    }

    const newPayload: Record<string, string | number[]> = {
      ...(data as Record<string, string | number[]>),
      profiles: profilesToArray.map((profileId) => Number(profileId)),
    };

    onSubmit(newPayload);
  };

  useEffect(() => {
    getProfiles();
  }, []);

  return (
    <Form onSubmit={preSubmit} handleSubmit={handleSubmit}>
      <Grid item xs={12}>
        <Typography color="text" fontWeight="bold">
          Estado
        </Typography>
        <SwitchField
          control={control}
          name="isdelete"
          checked={checked}
          handleChecked={handleChecked}
          label={checked ? "Activo" : "Inactivo"}
        />
      </Grid>
      <Grid container alignItems="flex-end" spacing={3}>
        <Grid item xs={3}>
          <SelectForm
            name="nacionalidad"
            variant="standard"
            control={control}
            options={NATIONALITY}
            disabled={true}
            label="Nacionalidad"
            defaultValue="V"
            rules={RULES.requerido}
            error={Boolean(errors.nacionalidad)}
          />
        </Grid>
        <Grid item xs={9}>
          <TextField
            name="cedula"
            type="text"
            label="Cedula"
            variant="standard"
            control={control}
            disabled={true}
            rules={RULES.cedula}
            error={Boolean(errors.cedula)}
            errmsg={errors.cedula}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="email"
            type="text"
            label="Correo"
            variant="standard"
            control={control}
            rules={RULES.correo}
            error={Boolean(errors.email)}
            errmsg={errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="failedAttempts"
            type="text"
            label="Intentos Fallidos"
            variant="standard"
            control={control}
            rules={RULES.number}
            error={Boolean(errors.failedAttempts)}
            errmsg={errors.failedAttempts}
          />
        </Grid>
        <Grid item xs={12}>
        <PasswdField
          name="password"
          control={control}
          label="Contraseña"
          variant="standard"
          rules={RULES.contraseña_admin}
          error={Boolean(errors.password)}
          errmsg={errors.password}
        />
         </Grid>
        {/* <Grid item xs={12}>
          <PasswdField
            name="password"
            control={control}
            label="Contraseña"
            variant="standard"
            rules={RULES.contraseña}
            error={Boolean(errors.password)}
            errmsg={errors.password}
          />
        </Grid> */}
      </Grid>
      {getPermiso(user.permissions, PERMISSIONS.SHOW_RELATIONS_ROLES) && (
        <CheckField
          name="profiles"
          label="Asignar Rol(es)"
          control={control}
          options={profiles}
          checkedState={checkedProfiles}
          handleCheck={handleCheck}
          setCheckedState={setCheckedProfiles}
          itemLabel="name"
          error={!Object.keys(checkedProfiles).length}
        />
      )}

      <Button
        fullWidth
        variant="contained"
        type="submit"
        disabled={loadingService}
      >
        {loadingService ? <CircularProgress size={24} /> : "Actualizar"}
      </Button>
    </Form>
  );
};

export default FormUsers;
