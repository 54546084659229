import { request } from "config/request";
import { API_NOMINA, API_PDF } from "constants/index";
import { iRequest } from "interfaces/api";
import { Tparam } from "types";
/**
 * Sends a request to '/constancia' with the given payload and parameters.
 *
 * @param props - The request properties.
 * @returns A promise that resolves to the response of the request.
 */
export const UserService = (props: iRequest) => {
  const { authRequire, payload } = props;

  return request(`${API_NOMINA}/datos-trabajador`, {
    method: "get",
    params: payload,
    authRequire,
    isBearer: true,
  });
};

export const UniqueUser = (props: iRequest) => {
  const { payload } = props as any;
  return request(
    `${API_NOMINA}/consultaAdmin/${payload.cedula}/${payload.nacionalidad}`,
    {
      method: "get",
      data: { params: payload },
      isBearer: true,
    }
  );
};

export const UserRecibo = (props: iRequest) => {
  const { isBearer, payload } = props;
  return request(`${API_PDF}/recibo`, {
    method: "post",
    data: payload,
    isBearer,
  });
};

export const UserChangePassword = (props: iRequest) => {
  const { payload, params } = props;
  delete payload?.passwordNew2;
  return request(`${API_NOMINA}/update-password`, {
    method: "post",
    data: { params: payload },
    isBearer: true,
    params: params,
  });
};

export const FindConstancyService = (props: iRequest) => {
  const { authRequire, payload, params } = props;
  return request(`/seekconstancy/${payload}`, {
    method: "get",
    authRequire,
    params: params,
  });
};

export const getConstancyDataService = (props: iRequest) => {
  const { isBearer, payload } = props;

  return request(`${API_PDF}/constancia`, {
    method: "post",
    isBearer,
    data: payload,
    // {
    //   "tcontrato": "F",
    //   "origen": "V",
    //   "cedula": 16113204
    // }
  });
};

export const getValidConstancyService = (props: iRequest) => {
  const { payload } = props;

  return request(`${API_PDF}/seekconstancy/${payload}`, {
    method: "get",
  });
};

export const createArcService = (props: iRequest) => {
  const { authRequire, params } = props;

  return request(`${API_PDF}/arc`, {
    method: "post",
    responseType: "blob",
    data: {
      ...params,
    },
    // data: { params: payload },
    isBearer: authRequire,
    // params: params
  });
};

/**
 * Obtener todos los usuarios de la API
 * @method getAllUsersService
 * @param props: {iRequest}
 * @returns void
 */
/* export const getAllUsersService = (
  props: iRequest
): Promise<AxiosResult<unknown>> => {
  const { authRequire } = props;
  return request('/users/all', {
    method: 'GET',
    authRequire,
    params
  });
}; */

export const getAllUsersService = ({ params }: iRequest) => {
  const { page, size, ...rest } = params as Tparam;
  return request(`${API_NOMINA}/users/all`, {
    method: "get",
    isBearer: true,
    params: { page: page + 1, size, ...rest },
  });
};

export const updateUserServices = ({ params, payload, isBearer }: iRequest) => {
  return request(`${API_NOMINA}/updateUsers/${params}`, {
    method: "put",
    isBearer,
    data: payload,
  });
};
