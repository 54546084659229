import { TextField } from "components/presentationals/Fields";
import { Form } from "components/presentationals/Formularies";

import { RULES } from "constants/index";

import { useFormHook } from "hooks/useFormHook";
import React, { useState, useEffect } from "react";
import { TFormProps, TOptionsChecked } from "types";
import { Grid, CircularProgress, Button } from "@mui/material";

import { useAppDispatch } from "hooks/useRedux";
import { getAllModulesServices } from "services";
import CheckField from "components/presentationals/Fields/CheckField";
import { TCheckedState } from "types/common";

import { alertsMessages } from "reducers/appSlice";
import { getAllPermissionsServices } from "services/permissions";
import TextFieldSelect from "components/presentationals/Fields/TextFieldSelect";
import { PROFILES } from "utils/enums";

const FormPofiles: React.FC<TFormProps> = ({
  onSubmit,
  loadingService,
  rowData,
}) => {
  const dispatch = useAppDispatch();
  const { data } = rowData;

  const { control, errors, handleSubmit } = useFormHook(data);
  const objCheckedDefaultModules = data.modules_profiles
    ? data.modules_profiles.reduce((acc, el) => {
        if (!acc[el.module_id]) {
          acc[el.module_id] = true;
        }
        return acc;
      }, {} as TCheckedState)
    : {};

  const navOptions = Object.values(PROFILES).map((a: any) => ({
    id: a,
    name: a,
  }));

  const objCheckedDefaultPermissions = data.permissions_profiles
    ? data.permissions_profiles.reduce((acc, el) => {
        if (!acc[el.permissionId]) {
          acc[el.permissionId] = true;
        }
        return acc;
      }, {} as TCheckedState)
    : {};

  const [modules, setModules] = useState<TOptionsChecked[]>([]);
  const [checkedPermissions, setCheckedPermissions] = useState<TCheckedState>(
    objCheckedDefaultPermissions
  );
  const [permissionsState, setPermissions] = useState<TOptionsChecked[]>([]);
  const [checkedModules, setCheckedModules] = useState<TCheckedState>(
    objCheckedDefaultModules
  );

  const handleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    stateChecked: TCheckedState,
    setCheckedState: React.Dispatch<React.SetStateAction<TCheckedState>>
  ) => {
    setCheckedState({
      ...stateChecked,
      [event.target.value]: event.target.checked,
    });
    const currentId = event.target.value;
    const currentChecked = event.target.checked;

    const isIntersected = Object.keys(stateChecked).some(
      (el) => Number(el) === Number(currentId)
    );

    if (!isIntersected && currentChecked) {
      /* Insertar */
      setCheckedState((prev) => ({
        ...prev,
        [currentId]: currentChecked,
      }));
    } else {
      const newCheckdList = Object.keys({ ...stateChecked })
        .filter((el) => el !== currentId)
        .reduce((acc, el) => {
          if (!acc[el]) {
            acc[el] = true;
          }
          return acc;
        }, {} as Record<string | number, boolean>);

      setCheckedState(newCheckdList);
    }
  };

  const getPermissions = async () => {
    try {
      const { data } = await getAllPermissionsServices({
        params: { page: 0, size: 1000 },
        isBearer: true,
      });

      setPermissions(data.data as TOptionsChecked[]);
    } catch (error: any) {
      dispatch(alertsMessages(error?.response?.data?.alert));
      throw Error();
    }
  };

  const getModules = async () => {
    try {
      const { data } = await getAllModulesServices({
        params: { page: 0, size: 1000 },
        isBearer: true,
      });
      setModules(data.data as TOptionsChecked[]);
    } catch (error: any) {
      dispatch(alertsMessages(error?.response?.data?.alert));
      throw Error();
    }
  };

  const preSubmit = async (data: unknown) => {
    const modulesToArray = Object.keys(checkedModules);
    const permissionsToArray = Object.keys(checkedPermissions);
    if (!modulesToArray.length) {
      dispatch(
        alertsMessages({
          name: "error",
          message: "¡Debe seleccionar al menos un Rol!",
        })
      );
      return;
    }
    if (!permissionsToArray.length) {
      dispatch(
        alertsMessages({
          name: "error",
          message: "¡Debe seleccionar al menos un Permiso!",
        })
      );
      return;
    }

    const newPayload: Record<string, string | number[]> = {
      ...(data as Record<string, string | number[]>),
      modules_id: modulesToArray.map((profileId) => Number(profileId)),
      permissions_id: permissionsToArray.map((permissionsId) =>
        Number(permissionsId)
      ),
    };

    onSubmit(newPayload);
  };

  useEffect(() => {
    getModules();
    getPermissions();
  }, []);
  return (
    <Form onSubmit={preSubmit} handleSubmit={handleSubmit}>
      <Grid container alignItems="flex-end" spacing={3}>
        <Grid item xs={12}>
          <TextFieldSelect
            name="name"
            label="Nombre del Perfil"
            control={control}
            variant="standard"
            options={navOptions}
            rules={RULES.requerido}
            autoFocus={true}
            error={Boolean(errors.path)}
            /*      defaultValue={(data as { name: string }).name} */
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="description"
            type="text"
            label="Descripción del Perfil"
            variant="standard"
            control={control}
            multiline
            rules={RULES.requerido}
            error={Boolean(errors.description)}
            errmsg={errors.description}
          />
        </Grid>
      </Grid>
      <CheckField
        name="Modules"
        label="Asignar Modulos"
        control={control}
        options={modules}
        checkedState={checkedModules}
        handleCheck={handleCheck}
        setCheckedState={setCheckedModules}
        itemLabel="name"
        error={!Object.keys(checkedModules).length}
      />
      <CheckField
        name="permissionsId"
        label="Asignar Permisos"
        control={control}
        options={permissionsState}
        checkedState={checkedPermissions}
        handleCheck={handleCheck}
        setCheckedState={setCheckedPermissions}
        itemLabel="description"
        error={!Object.keys(checkedPermissions).length}
      />

      <Button
        fullWidth
        variant="contained"
        type="submit"
        disabled={loadingService}
      >
        {loadingService ? <CircularProgress size={24} /> : "Actualizar"}
      </Button>
    </Form>
  );
};

export default FormPofiles;
