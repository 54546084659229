import React, { memo } from "react";
import { Helmet } from "react-helmet-async";
/* interfaces */
import IPage from "../../interfaces/page";
import { IPROPS } from "interfaces/index";
import { SubmitHandler } from "react-hook-form";

/* MUI */
import { Typography, Container } from "@mui/material";
import FormArc from "./components/FormArc";
/* Helpers */
import { getYearHelper, searchPayloadLocal } from "utils/helpers";
/* Redux */
import { clearErrorsServer } from "reducers/appSlice";
import { userARCWorker } from "reducers/userSlice";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { localToken } from "../../constants";

const Arc: React.FC<IPage> = () => {
  const DEFAULT_VALUES: IPROPS | undefined = {
    anio: new Date(`${new Date().getFullYear() - 1}-12-31`),
    cedula: searchPayloadLocal(localToken).cedula,
  };

  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.authReducer);

  const { userData } = useAppSelector((state) => state.userReducer);

  const { isGenerating } = useAppSelector((state) => state.userReducer);
  const onSubmit: SubmitHandler<IPROPS> = async (data) => {
    const payload = {
      nacionalidad: user.nacionalidad,
      cedula: user.cedula,
      anio: getYearHelper(data.anio),
      tcontrato: userData[0].tipo_persona[0],
    };

    await dispatch(userARCWorker(payload));
    await clearErrorsServer();
  };
  return (
    <>
      <Helmet>
        <title>ME - ARC-V</title>
      </Helmet>
      <section>
        <Container maxWidth="sm">
          <Typography className="page-title" component="h1">
            ARC-V
          </Typography>
          <FormArc
            onSubmit={onSubmit}
            isLoading={isGenerating}
            defaultValues={DEFAULT_VALUES}
          />
        </Container>
      </section>
    </>
  );
};

export default memo(Arc);
