import { request } from "config/request";
import { API_CUENTA, API_NOMINA } from "constants/index";
import { iRequest } from "interfaces/api";

export const LoginService = (props: iRequest) => {
  const { params, authRequire, payload } = props;
  delete payload?.captcha;
  return request(API_NOMINA + "/sign-in", {
    method: "post",
    data: payload,
    authRequire,
    params: params,
  });
};

export const RecoverService = (props: iRequest) => {
  const { authRequire, payload } = props;
  return request(`${API_NOMINA}/recover`, {
    method: "post",
    data: payload,
    authRequire,
  });
};
export const UpdateService = (props: iRequest) => {
  const { params, authRequire, payload } = props;
  return request(API_CUENTA + "/cuenta/updatepassword", {
    method: "post",
    data: { params: payload },
    authRequire,
    params: params,
  });
};

export const VerifyService = (props: iRequest) => {
  const { authRequire, params } = props as iRequest;
  /*   axios.get(`${API_NOMINA}/check-status/${params?.cedula}/${ format(new Date(params?.fecha), 'dd-MM-yyyy')}/${params?.origen}`) */

  return request(
    `${API_NOMINA}/check-status/${params?.cedula}/${params?.fechana}/${params?.nacionalidad}`,
    {
      method: "get",
      authRequire,
    }
  );
};

export const RegisterService = (props: iRequest) => {
  const { authRequire, payload } = props;
  delete payload?.captcha;

  return request(`${API_NOMINA}/sign-up`, {
    method: "post",
    data: payload,
    authRequire,
  });
};
