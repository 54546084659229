import React, { memo, useRef } from "react";
import { TextField } from "components/presentationals/Fields";

import ReCAPTCHA from "react-google-recaptcha";
/* @Mui */
import { Stack, Button, Alert, CircularProgress } from "@mui/material";
/* Hooks */

import { useFormHook } from "hooks/useFormHook";
/* constants */
import { RULES, SITE_KEY } from "constants/index";
import { useAppDispatch } from "hooks/useRedux";
import { useNavigate } from "react-router";
import { recoverWorker } from "reducers/authSlice";
import { SubmitHandler } from "react-hook-form";
import { IPROPS } from "interfaces";
import { useWindowSize } from "hooks/useWindowSize";

const RecoverForm: React.FC = () => {
  const [loading, setLoading] = React.useState(false);

  let captcha: any = useRef(null);
  const DEFAULT_VALUES = {
    email: "",
  };

  const { control, handleSubmit, errors, setError, clearErrors } =
    useFormHook(DEFAULT_VALUES);

  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<IPROPS> = async (data) => {
    try {
      setLoading(true);
      if (process.env.NODE_ENV === "development") {
        await dispatch(recoverWorker(data));
      } else {
        if (captcha?.current.getValue()) {

          await dispatch(recoverWorker(data));
          navigate("/iniciar-sesion");
        } else {
          setError("captcha", {
            type: "validado",
            message: "No esta validado por el captcha",
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onChange = () => {
    clearErrors();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4} alignItems={"center"}>
        <TextField
          name="email"
          type="text"
          label="Correo electrónico o usuario"
          variant="standard"
          control={control}
          rules={RULES.requerido}
          error={Boolean(errors.email)}
          errmsg={errors.email}
        />
        {useWindowSize().width < 420 ? (
          <ReCAPTCHA
            ref={captcha}
            onChange={onChange}
            sitekey={SITE_KEY}
            size="compact"
          />
        ) : (
          <ReCAPTCHA ref={captcha} onChange={onChange} sitekey={SITE_KEY} />
        )}

        {errors.captcha && (
          <Alert severity="warning">No esta validado por el captcha</Alert>
        )}
        <Button
          sx={{ width: "100%" }}
          variant="contained"
          type="submit"
          disabled={loading}
        >
          {loading ? (
            <CircularProgress
              sx={{ height: "24.5px !important", width: "24.5px !important" }}
            />
          ) : (
            "Recuperar contraseña"
          )}
        </Button>
      </Stack>
    </form>
  );
};

export default memo(RecoverForm);
