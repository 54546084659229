/* @Compoenetes */
import AlertInfo from "components/AlertInfo";
import { TextField } from "components/presentationals/Fields";
import SelectForm from "components/presentationals/Fields/TextFieldSelect";
import { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
/* @Mui */
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Slide,
  Stack,
} from "@mui/material";
/* Hooks */
import { useFormHook } from "hooks/useFormHook";
/* @Constant */
// import SwalConfirm from "components/presentationals/DialogConfirm/SwalConfirm"; // Importa SwalConfirm
import { NATIONALITY, RULES, SITE_KEY } from "constants/index";
import { useAppDispatch } from "hooks/useRedux";
import { useWindowSize } from "hooks/useWindowSize";
import { IPROPS } from "interfaces";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router";
import { alertsMessages, clearErrorsServer } from "reducers/appSlice";
import { clearVerified } from "reducers/authSlice";
import NAVIGATION from "routes/navigation";
import { RegisterService, VerifyService } from "services/auth";
import RestForm from "./RestForm";
import { API_NOMINA } from "constants/index";
// import { json } from "stream/consumers";
// import { Password } from "@mui/icons-material";
import Swal from "sweetalert2";

export type IFormRegister = {
  nacionalidad: string;
  cedula: string;
  email: string;
  emailConfirm: string;
  telefono: string;
  prefixPhone: string;
  captcha: any;
  fechana: string;
};

const DEFAULT_VALUES: IFormRegister = {
  nacionalidad: "V",
  cedula: "",
  email: "",
  emailConfirm: "",
  prefixPhone: "0414",
  telefono: "",
  captcha: null,
  fechana: "",
};

const RegisterForm = () => {
  const navigate = useNavigate();
  const [isVerified, setIsVerfied] = useState<boolean>(false);
  let captcha: any = useRef(null);
  const dispatch = useAppDispatch();
  const [datos, setDatos] = useState(DEFAULT_VALUES);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    errors,
    getValues,
    setError,
    clearErrors,
    reset,
  } = useFormHook(DEFAULT_VALUES);

  const onChange = () => {
    clearErrors();
  };

  const onClear: () => void = () => {
    setIsVerfied(false);
    reset(DEFAULT_VALUES);
  };

  const onVerify = async (params: any) => {
    setIsLoading(true);
    try {
      const response = await VerifyService({ params });
      reset({
        ...DEFAULT_VALUES,
        fechana: response?.data.fechaNacimiento,
        ...response?.data,
      });
      return dispatch(
        alertsMessages({
          message: "Usuario verificado exitosamente",
          name: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        alertsMessages({
          message: error?.response?.data?.alert?.message,
          name: error?.response?.data?.alert?.name,
        })
      );
      throw Error();
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegister = async (payload) => {
    setIsLoading(true);
    const telefono = payload.prefixPhone + payload.telefono;
    delete payload.fechaNacimiento;
    delete payload.telefono;
    delete payload.prefixPhone;
    try {
      const formatPayload = {
        ...payload,
        telefono: telefono,
      };

      await RegisterService({ payload: formatPayload });
      await clearErrors();
      reset(DEFAULT_VALUES);
      dispatch(
        alertsMessages({
          message:
            "Usuario registrado exitosamente, su clave será enviada a su correo",
          name: "success",
        })
      );
      await navigate(NAVIGATION.LOGIN);
    } catch (error: any) {
      dispatch(
        alertsMessages({
          message: error?.response?.data?.alert?.message,
          name: error?.response?.data?.alert?.name,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirm = async (data) => {
    setDatos(data);
    console.log(data);
    const email = data.email;
    const datos = data;

    const response = await fetch(`${API_NOMINA}/verify-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cedula: data.cedula,
        fechana: data.fechana,
        nacionalidad: data.nacionalidad,
        email: data.email,
      }),
    });

    console.log("pase el response", response.status);

    // Puedes comprobar si la respuesta fue exitosa
    if (!response.ok) {
      dispatch(
        alertsMessages({
          message: response.statusText,
          name: "error",
        })
      );
      console.error("Error en la solicitud:", response.statusText);
      return;
    }

    const result = await response.json(); // Obtén el resultado de la respuesta
    console.log("response me da", result);

    const { value: password } = await Swal.fire({
      title: "Revise su correo",
      text: `Se ha enviado un código a su correo ${email}, por favor ingréselo aquí.`,
      icon: "info",
      input: "text", // Tipo de input
      inputPlaceholder: "Ingrese el código",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      inputValidator: (value) => {
        if (!value) {
          return "Por favor ingrese el código";
        }
      },
    });
    console.log("reconozco email?", email);

    // Verifica que el usuario ingresó un código
    if (password) {
      // Llama a handleRegisterData con el código ingresado
      handleRegisterData(password, email, datos);
    }
  };

  const handleRegisterData = async (password, email, datos) => {
    console.log("password", password);
    console.log("email", email);

    try {
      const response = await fetch(`${API_NOMINA}/check-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });

      console.log("response de checkemail", response.status);

      if (response.status === 200) {
        await handleRegister(datos);
      } else {
        handleConfirm(datos);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit: SubmitHandler<IPROPS> = async (data) => {
    if (process.env.NODE_ENV === "development") {
      if (!isVerified) {
        await onVerify(data);
        setIsVerfied(true);
      } else {
        handleConfirm(data);
      }
    } else {
      if (captcha?.current?.getValue()) {
        if (!isVerified) {
          await onVerify(data);
          setIsVerfied(true);
        } else {
          handleConfirm(data);
        }
      } else {
        setError("captcha", {
          type: "validado",
          message: "No está validado por el captcha",
        });
      }
    }

    await dispatch(clearErrorsServer());
  };

  useEffect(() => {
    dispatch(clearVerified());
  }, [dispatch]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          spacing={useWindowSize().width < 600 ? 2 : 5}
          alignItems="center"
          width="100%"
          sx={{
            maxHeight: "100vh",
            overflow: "auto",
            padding: 2,
          }}
        >
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={12} sm={4}>
              <SelectForm
                name="nacionalidad"
                variant="standard"
                control={control}
                options={NATIONALITY}
                label="Nacionalidad"
                defaultValue="V"
                rules={RULES.requerido}
                error={Boolean(errors.nacionalidad)}
                readOnly={isVerified}
                disabled={isVerified}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                name="cedula"
                type="text"
                label="Cédula"
                variant="standard"
                control={control}
                rules={RULES.cedula}
                error={Boolean(errors.cedula)}
                errmsg={errors.cedula}
                readOnly={isVerified}
                disabled={isVerified}
              />
            </Grid>
          </Grid>

          <TextField
            name="fechana"
            type="date"
            label="Fecha de Nacimiento"
            variant="standard"
            control={control}
            rules={RULES.requerido}
            labelProps={{ shrink: true }}
            error={Boolean(errors.fechana)}
            errmsg={errors.fechana}
            disabled={isVerified}
          />
          <Slide
            style={{ width: "100%" }}
            direction="up"
            in={isVerified}
            mountOnEnter
            unmountOnExit
          >
            <RestForm
              control={control}
              errors={errors}
              getValues={getValues}
              setError={setError}
              clearErrors={clearErrors}
            />
          </Slide>
          <ReCAPTCHA
            ref={captcha}
            onChange={onChange}
            sitekey={SITE_KEY}
            size={useWindowSize().width < 450 ? "compact" : "normal"}
          />

          {errors.captcha && (
            <Alert severity="warning">No está validado por el captcha</Alert>
          )}

          {isVerified && (
            <Button
              fullWidth
              color="error"
              variant="contained"
              onClick={onClear}
              type="reset"
            >
              Cancelar
            </Button>
          )}
          <Button
            fullWidth
            variant="contained"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} />
            ) : !isVerified ? (
              "Verificar"
            ) : (
              "Registrar"
            )}
          </Button>
          <AlertInfo texto="Su contraseña será enviada a su correo al finalizar el registro." />
        </Stack>
      </form>
    </>
  );
};

export default RegisterForm;
