// Vendors
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { localToken } from "constants/index";
/* Interfaces */
import { Auth, IAction, iPayloadString } from "interfaces/index";
import jwt_decode from "jwt-decode";
/* Services */
import {
  LoginService,
  RecoverService,
  RegisterService,
  UpdateService,
  VerifyService,
} from "services/auth";
/* helpers */
import { removeItemLocal, searchItemLocal, setItemLocal } from "utils/helpers";
import { alertsMessages } from "./appSlice";
/* SweetAlert */
import Swal from "sweetalert2";

const PREFIX = "AUTH";
const authAdapter = createEntityAdapter<Auth>({});
const initialState: Auth = {
  isAuthenticated: false,
  isLoading: false,
  success: false,
  isVerified: false,
  user: {
    email: "",
    nacionalidad: "",
    cedula: "",
    fechana: "",
    menu: "",
    profiles: "",
    permissions: "",
  },
};

/**
 * Iniciar sesión
 * @param username :string, 
 * @param password :string, 
  @return mixed
 */
export const login = createAsyncThunk(
  `${PREFIX}/INICIAR-SESION`,
  async (payload: iPayloadString, { dispatch }) => {
    try {
      const response = await LoginService({ payload });

      const token = response.data;
      setItemLocal(localToken, token);
      const decode = jwt_decode(token);

      console.log("decode", decode);

      const { email, fechana, nacionalidad, cedula, menu } = decode as any;
      console.log("menu", menu.modules);
      return {
        user: {
          email,
          fechana,
          nacionalidad,
          cedula,
          menu: menu.modules,
          profiles: menu.profiles,
          permissions: menu.permissions,
        },
      };
    } catch (error: any) {
      console.log(error.response.data.alert);
      if (
        error?.response?.data?.alert?.message ===
        "Intento fallido de inicio de sesión 2 de 3"
      ) {
        Swal.fire({
          title: "Precaución",
          text: "Te queda 1 intento para iniciar sesión. Por favor, revisa tu usuario y contraseña para evitar que se bloquee tu cuenta.",
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else if (
        error?.response?.data?.alert?.message === "Su usuario a sido bloqueado"
      ) {
        Swal.fire({
          title: "Usuario bloqueado",
          text: "Su usuario a sido bloqueado, si desea desbloquear su usuario use el boton Recuperar contraseña.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else {
        dispatch(
          alertsMessages({
            message: error?.response?.data?.alert.message,
            name: error?.response?.data?.alert.name,
          })
        );
      }
      throw Error();
    }
  }
);

/**
 * Registrar usuario
 * @param username :string, 
 * @param email :string, 
 * @param password: string,
 * @param origen: string, 
 * @param cedula: string
  @return mixed
 */
export const registerWorker = createAsyncThunk(
  `${PREFIX}/REGISTRAR-TRABAJADOR`,
  async (payload: iPayloadString, { dispatch }) => {
    try {
      const response = await RegisterService({ payload });

      return dispatch(
        alertsMessages({
          message: "Usuario registrado exitosamente",
          name: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        alertsMessages({
          message: error?.response?.data?.message,
          name: "error",
        })
      );
      throw Error();
    }
  }
);
/**
 * Recuperar contraseña 
 * @param username :string, 
  @return mixed
 */
export const recoverWorker = createAsyncThunk(
  `${PREFIX}/RECUPERAR-CONTRASEÑA`,
  async (payload: iPayloadString, { dispatch }) => {
    try {
      await RecoverService({ payload });

      return dispatch(
        alertsMessages({
          message:
            "Correo enviado exitosamente, por favor revise su correo electrónico",
          name: "success",
        })
      );
    } catch (error: any) {
      dispatch(alertsMessages(error?.response?.data?.alert));
      throw Error();
    }
  }
);
export const updateWorker = createAsyncThunk(
  `${PREFIX}/ACTUALIZAR-CONTRASEÑA`,
  async (payload: iPayloadString, { dispatch }) => {
    try {
      const response = await UpdateService({ payload, authRequire: true });
      dispatch(alertsMessages(response?.data?.alert));
    } catch (error: any) {
      dispatch(alertsMessages(error?.response?.data?.alert));
      throw Error();
    }
  }
);

/**
 * Verificar token al refrescar *
  @return mixed
 */
export const reloadingBrowser = createAsyncThunk(
  `${PREFIX}/RELOADING-BROWSER`,
  () => {
    const token = searchItemLocal(localToken);

    if (!token) {
      removeItemLocal(localToken);
    }

    const decoded: any = jwt_decode(token);

    if (decoded.exp > Math.floor(Date.now() / 1000)) {
      const { user_id, email, fechana, nacionalidad, cedula, menu } = decoded;

      return {
        user: {
          id: user_id,
          email,
          nacionalidad,
          cedula,
          fechana,
          menu: menu.modules,
          profiles: menu.profiles,
          permissions: menu.permissions,
        },
      };
    } else removeItemLocal(localToken);
  }
);

/**
 * Cerrar sesion *
  @return mixed
 */
export const logout = createAsyncThunk(`${PREFIX}/logout`, () =>
  removeItemLocal(localToken)
);

export const authSlice = createSlice({
  name: PREFIX,
  initialState: authAdapter.getInitialState(initialState),
  reducers: {
    clearVerified(state) {
      state.isVerified = false;
    },
    authenticated(state) {
      state.isAuthenticated = true;
    },
    expiredAuth(state) {
      state.isAuthenticated = false;
    },
  },
  extraReducers: (build) => {
    /* Caso LoginWorker */
    build.addCase(login.pending, (state) => {
      state.isLoading = true;
    });

    build.addCase(login.fulfilled, (state, { payload }: IAction) => {
      state.isLoading = initialState.isLoading;
      state.isAuthenticated = true;
      state.user = payload.user;
    });
    build.addCase(login.rejected, (state) => {
      state.isLoading = initialState.isLoading;
    });

    /* caso Logout */
    build.addCase(logout.fulfilled, (state) => {
      state.isLoading = false;
      state.isAuthenticated = initialState.isAuthenticated;
      state.user = initialState.user;
    });
    build.addCase(logout.pending, (state) => {
      state.isLoading = true;
    });

    /* Caso RegisterWorker */
    build.addCase(registerWorker.pending, (state) => {
      state.isLoading = true;
    });
    build.addCase(registerWorker.fulfilled, (state) => {
      state.isLoading = initialState.isLoading;
      state.isVerified = initialState.isVerified;
    });
    build.addCase(registerWorker.rejected, (state) => {
      state.isLoading = initialState.isLoading;
      state.isVerified = initialState.isVerified;
    });
    /* Caso RecoverWorker */
    build.addCase(recoverWorker.pending, (state) => {
      state.isLoading = true;
    });
    build.addCase(recoverWorker.fulfilled, (state) => {
      state.isLoading = initialState.isLoading;
    });
    build.addCase(recoverWorker.rejected, (state) => {
      state.isLoading = initialState.isLoading;
    });
    /* Caso UpdateWorker */
    build.addCase(updateWorker.pending, (state) => {
      state.isLoading = true;
      state.success = initialState.success;
    });
    build.addCase(updateWorker.fulfilled, (state) => {
      state.isLoading = initialState.isLoading;
      state.success = true;
    });
    build.addCase(updateWorker.rejected, (state) => {
      state.isLoading = initialState.isLoading;
      state.success = initialState.success;
    });

    /* Caso ReloadBrowser */
    build.addCase(reloadingBrowser.fulfilled, (state, { payload }: IAction) => {
      state.isAuthenticated = true;
      state.user = payload.user;
    });
    build.addCase(reloadingBrowser.rejected, (state) => {
      state.isAuthenticated = false;
      state.user = initialState.user;
    });
  },
});

//Actions
export const { clearVerified } = authSlice.actions;
// Reducer
export default authSlice.reducer;
