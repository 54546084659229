import React, { forwardRef, memo } from "react";
import SelectForm from "components/presentationals/Fields/TextFieldSelect";
import { TextField } from "components/presentationals/Fields";
import { PHONES, RULES } from "constants/index";

/* @Mui */
import { Grid, Stack } from "@mui/material";

const RestForm: React.FC<any> = forwardRef(({ control, errors }, ref) => {
  return (
    <Stack ref={ref} spacing={4} sx={{ width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <SelectForm
            name="prefixPhone"
            variant="standard"
            control={control}
            options={PHONES}
            label="Prefijo"
            defaultValue="0414"
            rules={RULES.requerido}
            error={Boolean(errors.prefixPhone)}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            name="telefono"
            type="text"
            label="Teléfono"
            variant="standard"
            control={control}
            rules={RULES.phone}
            error={Boolean(errors.telefono)}
            errmsg={errors.telefono}
            maxLength={7}
          />
        </Grid>
      </Grid>

      <TextField
        name="email"
        type="email"
        label="Correo"
        variant="standard"
        control={control}
        rules={RULES.correo}
        error={Boolean(errors.email)}
        errmsg={errors.email}
      />
    </Stack>
  );
});

export default memo(RestForm);
