import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { SubmitHandler } from "react-hook-form";
import FormReceipt from "./components/FormReceipt";
/* interfaces */
import { IPROPS } from "interfaces/index";
import IPage from "../../interfaces/page";

/* MUI */
import { Container, Typography } from "@mui/material";

/* Helpers */
import { localToken } from "constants/index";
import { isEmpty } from "lodash";
import createRecepit from "pdfs/receipt";
import {
  getMonthHelper,
  getYearHelper,
  searchPayloadLocal,
} from "utils/helpers";

/* Redux */
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { clearErrorsServer } from "reducers/appSlice";
import { clearRecibo, userReciboWorker } from "reducers/userSlice";

const Receipt: React.FC<IPage> = () => {
  const DEFAULT_VALUES: IPROPS | undefined = {
    date: new Date(),
    quincena: "1",
    cedula: searchPayloadLocal(localToken).cedula || null,
  };

  const { reciboData, userData } = useAppSelector((state) => state.userReducer);

  /* @State */
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<IPROPS> = async (data) => {
    const payload: IPROPS = {
      anio: getYearHelper(data.date),
      mes: getMonthHelper(data.date),
      quincena: data.quincena,
      cedula: data.cedula,
    };
    await dispatch(userReciboWorker(payload));
    await clearErrorsServer();
  };

  /*  useEffect(
     () => {
       if (!isEmpty(user.cedula)) {
         dispatch(userDataWorker({ cedula: user.cedula }));
       }
     },
     [dispatch, user]
   ); */

  useEffect(() => {
    if (!isEmpty(reciboData)) {
      createRecepit({ reciboData, userData });
      dispatch(clearRecibo());
    }
  }, [reciboData, userData, dispatch]);

  return (
    <>
      <Helmet>
        <title>MPPE - Recibo de pago</title>
      </Helmet>
      <section>
        <Container maxWidth="sm">
          <Typography className="page-title" component="h1">
            RECIBO DE PAGO
          </Typography>
          <FormReceipt
            onSubmit={onSubmit}
            defaultValues={DEFAULT_VALUES as IPROPS}
          />
        </Container>
      </section>
    </>
  );
};

export default Receipt;
