import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit';
// Types
import { IAction, iPayloadString, UserState } from 'interfaces/index';
/* Servicios */
import {
  createArcService,
  getAllUsersService,
  getConstancyDataService,
  getValidConstancyService,
  UserChangePassword,
  UserRecibo,
  UserService
} from 'services/user';
import { alertsMessages } from './appSlice';

const PREFIX = 'USER';

const initialState: UserState = {
  userData: [],
  isLoading: false,
  isGenerating: false,
  success: false,
  reciboData: {},
  verifyData: {},
  registerConstacia: false,

  // Remover luego (para administracion de usuarios)
  users: [],
  isLoadingUsers: false
};

const userAdapter = createEntityAdapter<UserState>({});

/**
 * Obtener Data del Usuario
 * @param cedula :string, 
    @return mixed
 */
export const userDataWorker = createAsyncThunk(
  `${PREFIX}/GET-USER`,
  async (payload: iPayloadString, { dispatch }) => {
    try {
      const response = await UserService({ payload, authRequire: false });

      // const datos = response?.data?.constanciaData;
      return response.data;
    } catch (error: any) {
      dispatch(alertsMessages(error?.response?.data?.alert));
      throw Error();
    }
  }
);

/**
 * Obtener recibo de pago del Usuario
 * @param anio :string, 
 * * @param mes :string, 
 * * @param quincena :string, 
 * * @param cedula :string, 
    @return mixed
 */
export const userReciboWorker = createAsyncThunk(
  `${PREFIX}/GET-RECIBO`,
  async (payload: iPayloadString, { dispatch }) => {
    try {
      const response = await UserRecibo({ payload, isBearer: true });

      const pdf = new Blob([new Uint8Array(response.data.data.data).buffer], {
        type: 'application/pdf'
      });
      const fileURL = URL.createObjectURL(pdf);

      const link = document.createElement('a');
      link.href = fileURL;
      link.target = '_blank';
      link.click();
      dispatch(
        alertsMessages({
          name: 'success',
          message: 'Recibo de pago creado correctamente'
        })
      );
    } catch (error: any) {
      dispatch(alertsMessages(error?.response?.data?.alert));
      throw Error();
    }
  }
);

export const userARCWorker = createAsyncThunk(
  `${PREFIX}/GET-ARC`,
  async (params: iPayloadString, { dispatch }) => {
    try {
      const response = await createArcService({ params, authRequire: true });

      const pdf = new Blob([new Uint8Array(response.data.data.data).buffer], {
        type: 'application/pdf'
      });
      const fileURL = URL.createObjectURL(pdf);

      const link = document.createElement('a');
      link.href = fileURL;
      link.target = '_blank';
      link.click();
      dispatch(
        alertsMessages({
          name: 'success',
          message: 'Arc creado correctamente'
        })
      );
    } catch (error: any) {
      dispatch(alertsMessages(error?.response?.data?.alert));
      throw Error();
    }
  }
);

/**
 * Actualiza la contraseña de usuario
 * @param passwordOld :string, 
 * * @param passwordNew :string, 
 * * @param passwordNew2 :string, 
    @return mixed
 */
export const userPasswordChange = createAsyncThunk(
  `${PREFIX}/POST-CHANGEPASSWORD`,
  async (payload: iPayloadString, { dispatch }) => {
    try {
      const response = await UserChangePassword({ payload, authRequire: true });
      return dispatch(alertsMessages(response?.data?.alert));
    } catch (error: any) {
      dispatch(alertsMessages(error?.response?.data?.alert));
      throw Error();
    }
  }
);

/**
 * Validar la constacia de usuario
 * @param codigo :string, 
    @return mixed
 */
export const findConstancy = createAsyncThunk(
  `${PREFIX}/GET-CONSTANCIA`,
  async (payload: iPayloadString, { dispatch }) => {
    try {
      const response = await getConstancyDataService({
        payload,
        isBearer: true
      });
      const pdf = new Blob([new Uint8Array(response.data.data.data).buffer], {
        type: 'application/pdf'
      });
      const fileURL = URL.createObjectURL(pdf);

      const link = document.createElement('a');
      link.href = fileURL;
      link.target = '_blank';
      link.click();
    } catch (error: any) {
      dispatch(alertsMessages(error?.response?.data?.alert));
      throw Error();
    }
  }
);

export const findValidConstancy = createAsyncThunk(
  `${PREFIX}/GET-VALIDAR-CONSTANCIA`,
  async (payload: iPayloadString, { dispatch }) => {
    try {
      const response = await getValidConstancyService({
        payload
      });

      return response.data;
    } catch (error: any) {
      dispatch(alertsMessages(error?.response?.data?.alert));
      throw Error();
    }
  }
);

/**
 * Validar la constacia de usuario
 * @param codigo :string, 
 * @param cedula :string,
    @return mixed
 */

/**
 * @function getAllUsers
 */
export const getAllUsers = createAsyncThunk(
  `${PREFIX}/GET_ALL`,
  async (_, { dispatch }) => {
    try {
      const { data } = await getAllUsersService({
        authRequire: true,
        payload: {}
      });

      return data;
    } catch (err) {
      dispatch(alertsMessages('Error al cargar usuarios'));
      throw new Error();
    }
  }
);

export const userSlice = createSlice({
  name: PREFIX,
  initialState: userAdapter.getInitialState(initialState),
  reducers: {
    clearRecibo(state) {
      state.reciboData = initialState.reciboData;
    }
  },
  extraReducers: (build) => {
    /* data del trabajador */
    build.addCase(userDataWorker.pending, (state) => {
      state.isLoading = true;
    });
    build.addCase(userDataWorker.fulfilled, (state, action: IAction) => {
      state.isLoading = initialState.isLoading;
      state.userData = action.payload;
    });
    build.addCase(userDataWorker.rejected, (state) => {
      state.isLoading = initialState.isLoading;
    });
    //cambio de clave
    build.addCase(userPasswordChange.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    build.addCase(userPasswordChange.fulfilled, (state) => {
      state.isLoading = initialState.isLoading;
      state.success = true;
    });
    build.addCase(userPasswordChange.rejected, (state) => {
      state.isLoading = initialState.isLoading;
      state.success = false;
    });

    /* Recibo de pago  */

    build.addCase(userReciboWorker.fulfilled, (state, action: IAction) => {
      state.isLoading = initialState.isLoading;
      state.reciboData = action.payload;
    });
    build.addCase(userReciboWorker.rejected, (state) => {
      state.isLoading = initialState.isLoading;
    });

    /* Verificando constancia  */
    build.addCase(findValidConstancy.pending, (state) => {
      state.isLoading = true;
    });
    build.addCase(findValidConstancy.fulfilled, (state, action: IAction) => {
      state.isLoading = initialState.isLoading;
      state.verifyData = action.payload;
    });
    build.addCase(findValidConstancy.rejected, (state) => {
      state.isLoading = initialState.isLoading;
    });

    /* Generarndo ARC */
    build.addCase(userARCWorker.pending, (state) => {
      state.isGenerating = true;
    });
    build.addCase(userARCWorker.fulfilled, (state) => {
      state.isGenerating = initialState.isGenerating;
    });
    build.addCase(userARCWorker.rejected, (state) => {
      state.isGenerating = initialState.isGenerating;
    });
  }
});

// Actions
export const { clearRecibo } = userSlice.actions;

// Reducer
export default userSlice.reducer;
