import React, { memo } from "react";
import { NavLink } from "react-router-dom";
import { MenuRoutes } from "routes";

/* Mui */
import { myStylesList } from "static/styles-mui/stylesMenu";

import {
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
  Typography,
} from "@mui/material";

/* enums */
import { DISPLAY } from "config/enums";
import { iconList } from "../constants";
import { useAppSelector } from "hooks/useRedux";

const ListMenu: React.FC<ListMenuProps> = ({ movil }) => {
  const classes = myStylesList({ movil });
  const { user } = useAppSelector((state) => state.authReducer);
  const { userData } = useAppSelector((state) => state.userReducer);

  return (
    <List component="nav" className={classes.nav}>
      {user.menu &&
        user.menu?.length &&
        userData?.[0]?.iplanta &&
        user.menu?.map(({ id, name, path, icon, display }: any) => {
          const Xicon = iconList[icon];
          if (name === "Evaluaciones") {
            if (userData?.[0]?.iplanta === "S") {
              return (
                DISPLAY.principal === display && (
                  <NavLink
                    key={id}
                    to={path}
                    end
                    className={({ isActive }) =>
                      isActive
                        ? `${classes.item} ${classes.active}`
                        : classes.item
                    }
                  >
                    <ListItem button>
                      {Xicon ? (
                        <ListItemIcon>
                          <Tooltip arrow title={name} placement="right">
                            <Xicon />
                          </Tooltip>
                        </ListItemIcon>
                      ) : null}
                      <Typography fontSize={16} variant="subtitle2">
                        {name}
                      </Typography>
                    </ListItem>
                  </NavLink>
                )
              );
            }

            return <></>;
          }
          return (
            DISPLAY.principal === display && (
              <NavLink
                key={id}
                to={path}
                end
                className={({ isActive }) =>
                  isActive ? `${classes.item} ${classes.active}` : classes.item
                }
              >
                <ListItem button>
                  {Xicon ? (
                    <ListItemIcon>
                      <Tooltip arrow title={name} placement="right">
                        <Xicon />
                      </Tooltip>
                    </ListItemIcon>
                  ) : null}
                  <Typography fontSize={16} variant="subtitle2">
                    {name}
                  </Typography>
                </ListItem>
              </NavLink>
            )
          );
        })}
    </List>
  );
};

/**
 * params para componente ListMenu
 * @method type ListMenuProps
 * @param movil?: {boolean} Verificar si es boolean (true, false)
 */
export type ListMenuProps = { movil: boolean };
export default memo(ListMenu);
