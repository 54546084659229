import { request } from 'config/request';
import { API_NOMINA } from 'constants/index';

// get all Permissions
export function getAllPermissionsServices({ params, isBearer }: any) {
  const { page, size } = params as any;
  return request(`${API_NOMINA}/permission/table`, {
    method: 'get',
    isBearer,
    params: { page: page + 1, size }
  });
}
// create Permissions
export function createPermissionsServices({ payload, isBearer }: any) {
  return request(`${API_NOMINA}/permission/create`, {
    method: 'post',
    isBearer,
    data: payload
  });
}
// update Permissions
export function updatePermissionsServices({
  payload,
  params,
  isBearer
}: any) {
  return request(`${API_NOMINA}/permission/updatepermission/${params}/`, {
    method: 'put',
    data: payload,
    isBearer
  });
}
// delete Permissions
export function deletePermissionsServices({ params, isBearer }: any) {
  return request(`${API_NOMINA}/permission/deletepermission/${params}/`, {
    method: 'delete',
    isBearer
  });
}
