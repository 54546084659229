import { Stack, Typography } from "@mui/material";
/* mui */
import React from "react";
import { Helmet } from "react-helmet-async";
/* @Interfaces */
import IPage from "../../interfaces/page";

import UpdateForm from "./components/UpdateForm";

const UpdatePassword: React.FC<IPage> = () => {
  <Helmet>
    <title>MPPE - Actualizar contraseña</title>
  </Helmet>;

  return (
    <Stack p={4} spacing={2}>
      <Typography className="page-title-login" component="h1">
        ACTUALIZAR CONTRASEÑA
      </Typography>
      <UpdateForm />
    </Stack>
  );
};

export default UpdatePassword;
