/* @componentes */
import { Container, Stack, Typography } from "@mui/material";
/* @Mui */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";
import { memo } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { PREFIX_DASHBOARD } from "routes/navigation";

import VerifyForm from "./components/VerifyForm";

const myStyles = makeStyles({
  link: {
    alignItems: "right",
    color: "red",
    fontWeight: "bold",
    padding: "10px 0 0 10px",
  },
});

const VerifyDocument = () => {
  const classes = myStyles();
  return (
    <>
      <Helmet>
        <title>MPPE - Validar documento</title>
      </Helmet>
      <Container maxWidth="xs" sx={{ margin: "auto" }}>
        <Link to={PREFIX_DASHBOARD}>
          <Stack
            className={classes.link}
            direction="row"
            alignItems="center"
            gap="5px"
          >
            <ArrowBackIcon color="primary" />
            <Typography
              textAlign="center"
              component="p"
              fontSize={17}
              color="primary"
            >
              Inicio
            </Typography>
          </Stack>
        </Link>
        <Stack sx={{ padding: "20px 0" }} spacing={2}>
          <Typography className="page-title-login" component="h1">
            VALIDAR DOCUMENTO
          </Typography>
          <VerifyForm />
        </Stack>
      </Container>
    </>
  );
};

export default memo(VerifyDocument);
