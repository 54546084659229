import { Button, CircularProgress } from '@mui/material';
import Stack from '@mui/material/Stack';
import PasswdField from 'components/presentationals/Fields/PasswdField';
import { RULES } from 'constants/index';
import { useFormHook } from 'hooks/useFormHook';
/* Hooks */
import { useAppSelector } from 'hooks/useRedux';
import { IPROPS } from 'interfaces/index';
import * as React from 'react';
import { useSubmitPassword } from '../hooks/useSubmitPassword';

const PasswordForm: React.FC = () => {
  const { isLoading } = useAppSelector((state) => state.userReducer);

  const DEFAULT_VALUES: IPROPS = {
    passwordNew: '',
    passwordNew2: '',
    passwordOld: ''
  };

  const {
    control,
    handleSubmit,
    getValues,
    errors,
    setError,
    clearErrors,
    reset
  } = useFormHook(DEFAULT_VALUES);

  const { onSubmit, handleBlur } = useSubmitPassword(
    getValues,
    reset,
    clearErrors,
    setError
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4} sx={{ padding: '20px' }}>
        <PasswdField
          name='passwordOld'
          value={getValues('passwordOld')}
          control={control}
          label='Contraseña actual'
          rules={RULES.contraseña}
          error={Boolean(errors.passwordOld)}
          errmsg={errors.passwordOld}
        />

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
          <PasswdField
            name='passwordNew'
            value={getValues('passwordNew')}
            control={control}
            label='Contraseña nueva'
            rules={RULES.contraseña}
            error={Boolean(errors.passwordNew)}
            errmsg={errors.passwordNew}
          />

          <PasswdField
            name='passwordNew2'
            value={getValues('passwordNew2')}
            control={control}
            label='Repetir contraseña'
            rules={RULES.contraseña}
            error={Boolean(errors.passwordNew2)}
            errmsg={errors.passwordNew2}
            handleBlur={handleBlur}
          />
        </Stack>
        <Button
          fullWidth
          variant='contained'
          type='submit'
          sx={{ marginTop: 4 }}
          disabled={isLoading}>
          {!isLoading ? 'Enviar' : <CircularProgress />}
        </Button>
      </Stack>
    </form>
  );
};

export default PasswordForm;
