// interface options {
//   id: string;
//   nombre: string;
// }
export { RULES } from "./rules";
export { NATIONALITY } from "./nationality";
export { DISPLAY } from "./display";
export { QUINCENA } from "./quincena";
export { iconList } from "./icons";
export { PHONES } from "./phones";
export const localToken = "x-me-token";
export const recoverToken = "x-me-recover";
export const SITE_KEY = "6LeFjF0pAAAAAO8JRaL1czwOm_J5n5aAg4oAmpG3";

export const SNACKBARS_IN_DISPLAY: number = 5;
export const API_CUENTA = process.env.REACT_APP_API_URL_USERS;
export const API_NOMINA = process.env.REACT_APP_API_URL_NOMINA;
export const API_PDF = process.env.REACT_APP_API_URL_PDF;
export const API_EVALUACIONES = process.env.REACT_APP_API_URL_EVALUACIONES;
